import { faCancel, faCircleInfo } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isOrganization, isPatient } from "fhir"
import { FC, useEffect } from "react"

import { useAccountCreditCards } from "account"
import { AppSubModules } from "app-modules"
import { useChartContext } from "chart-view"
import { ModulesId, SkeletonLoader } from "commons"
import { useOrganizationContext } from "organization"

import { useCPOERequestsContext, useInvoicePreview } from "../hooks"
import { CustomInvoiceData } from "../types"
import { InvoicePreviewSection } from "./InvoicePreviewSection"

const OrdersFinish: FC = () => {
  const { showSubModule } = useChartContext()
  const {
    setProcessEnabled,
    setIsProcessingActions,
    requestGroup,
    patientId,
    setAddressErrorMessage,
    readerAccount,
    activeRequestsInfo: { hasNutraRequest },
    checkoutAddressInfo: { nutrasShippingAddress },
    unmetRequirementsToInvoicePreview,
  } = useCPOERequestsContext()
  const { currentOrganizationCCAccount } = useOrganizationContext()
  const { defaultCreditCard, isLoading: isLoadingCC } = useAccountCreditCards(patientId)

  const { invoiceData, error, isLoadingPreview } = useInvoicePreview(
    patientId,
    requestGroup,
    !unmetRequirementsToInvoicePreview.isInvalid,
  )

  const practiceInvoice = invoiceData?.find(({ invoice }) => isOrganization(invoice?.recipient))
  const patientInvoice = invoiceData?.find(({ invoice }) => isPatient(invoice?.recipient))
  const missingOrganizationCreditCard = !!practiceInvoice && !currentOrganizationCCAccount?.creditCard
  const missingPatientCreditCard = !!patientInvoice && !defaultCreditCard

  useEffect(() => {
    if (!unmetRequirementsToInvoicePreview.isInvalid) setIsProcessingActions(true)

    return () => setIsProcessingActions(false)
  }, [])

  useEffect(() => {
    if (!isLoadingPreview && !isLoadingCC) {
      if (error) {
        if (error?.cause?.message.includes("Zip is not valid for the state")) {
          setAddressErrorMessage("Zip code is not valid for the state")
          showSubModule({ subModule: AppSubModules.checkout[ModulesId.CHECKOUT_CONFIG] })
        } else {
          setProcessEnabled(false)
        }
      } else {
        const hasInvoice = invoiceData?.length && invoiceData.every(({ invoice }) => invoice !== undefined)
        if (hasInvoice && !readerAccount && (missingOrganizationCreditCard || missingPatientCreditCard)) {
          setProcessEnabled(false)
        } else {
          setProcessEnabled(true)
        }
      }
      setIsProcessingActions(false)
    }
  }, [invoiceData, error, missingPatientCreditCard, missingOrganizationCreditCard, readerAccount])

  if (isLoadingPreview || isLoadingCC) return <SkeletonLoader repeats={4} loaderType="two-lines" />
  else if (error || unmetRequirementsToInvoicePreview.isInvalid) {
    return (
      <div className="flex flex-col h-full justify-center items-center ">
        <FontAwesomeIcon icon={faCancel} className="text-[4rem] text-red-400" />
        <div className="px-4 text-red-400 text-center mt-5">
          {error
            ? "Oops! Something went wrong trying to show the invoice preview"
            : unmetRequirementsToInvoicePreview.message}
        </div>
        {error && <small className="p-error mt-5">{error.cause?.message ?? "No message"}</small>}
      </div>
    )
  } else if (!invoiceData?.length) {
    return (
      <div className="flex  flex-col h-full justify-center items-center">
        <FontAwesomeIcon icon={faCircleInfo} className="text-[4rem] text-blue-400" />
        <div className="px-4 text-primary text-center mt-5">Thank you for your order</div>
        <small className="text-gray-500">Remember to process your items</small>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <InvoicePreviewSection
        invoiceData={practiceInvoice as CustomInvoiceData}
        nutrasShippingAddress={nutrasShippingAddress}
        showMedsAddress={hasNutraRequest}
      />
      <InvoicePreviewSection
        invoiceData={patientInvoice as CustomInvoiceData}
        readerAccount={readerAccount}
        nutrasShippingAddress={nutrasShippingAddress}
        showMedsAddress={hasNutraRequest}
      />
    </div>
  )
}

export { OrdersFinish }
