import { Reference } from "fhir"
import { Dropdown, DropdownProps } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"

const ReferenceDropdown: FC<Props> = ({
  options,
  label,
  className,
  horizontal,
  inputClassName,
  inputContainerClassName,
  optionLabel = "display",
  disabled,
  isClearable,
  filterBy = "display",
  showFilter = true,
  placeholder,
  labelClassName,
  handleChange,
  preferredReference,
  ...props
}) => {
  const [value, setValue] = useState<Reference>()

  return (
    <div
      className={classNames(
        "field relative",
        horizontal ? "inline-flex justify-between horizontal" : "flex flex-col",
        className,
      )}
    >
      {label && (
        <label
          className={classNames(
            "text-sm font-medium text-gray-700 mb-2",
            {
              "mr-3 mb-0 mt-2": horizontal,
            },
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <Dropdown
        optionLabel={optionLabel}
        options={options}
        filter={showFilter && filterBy !== undefined && options && options.length > 0}
        filterBy={filterBy}
        resetFilterOnHide={true}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.value)
          handleChange?.(e.value)
        }}
        dataKey="id"
        value={value}
        showClear={isClearable}
        disabled={disabled}
        className={classNames(
          "p-inputtext-sm",
          {
            horizontal: horizontal,
          },
          inputClassName,
        )}
        {...props}
      />
    </div>
  )
}

type Props = {
  options?: Reference[]
  label?: string
  className?: string
  filterBy?: string
  showFilter?: boolean
  horizontal?: boolean
  inputClassName?: string
  inputContainerClassName?: string
  labelClassName?: string
  optionLabel?: string
  disabled?: boolean
  isClearable?: boolean
  placeholder?: string
  handleChange?(value: Reference): void
  preferredReference?: Reference
} & DropdownProps

export { ReferenceDropdown }
