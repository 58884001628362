import { ErrorMessage, Field, FieldProps } from "formik"
import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea"
import { classNames } from "primereact/utils"
import { FC } from "react"

const TextAreaField: FC<Props> = ({
  field,
  label,
  rows = 4,
  className,
  inputClassName,
  placeholder,
  disabled,
  readOnly,
  labelClassName,
  ...textAreaProps
}) => (
  <Field name={field}>
    {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
      <div className={classNames("field flex flex-col relative", className)}>
        {label && (
          <label htmlFor={name} className={classNames("text-sm font-medium text-gray-700 mb-2", labelClassName)}>
            {label}
          </label>
        )}
        <InputTextarea
          id={name}
          name={name}
          onChange={onChange}
          value={value}
          className={classNames("p-inputtext-sm", { "p-invalid": touched && error }, inputClassName)}
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          {...textAreaProps}
        />
        <div className="flex items-start p-error h-2 mt-1">
          <ErrorMessage name={field}>{(msg) => <small>{msg}</small>}</ErrorMessage>
        </div>
      </div>
    )}
  </Field>
)

type Props = {
  field: string
  label?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  rows?: number
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
} & InputTextareaProps

export { TextAreaField }
