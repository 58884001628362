import { faCalendarDays, faEye, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import { Chip } from "primereact/chip"
import { useNavigate } from "react-router-dom"

import { formatsByTypes } from "data"
import { useOrganizationContext } from "organization"
import { formatDate } from "utils"

import { AvatarImage } from "./AvatarImage"
import { AvatarListItem } from "./AvatarListItem"

const AgendaListItem = ({
  patientAvatar,
  patientName,
  appointmentStart,
  appointmentDescription,
  appointmentType,
  color,
  appointmentStatus,
  patientId,
  action,
  onUnbook,
}: Props) => {
  const { currentOrganizationId } = useOrganizationContext()
  const navigate = useNavigate()
  const dropdownItems = [
    {
      label: "Unbook",
      icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
      disabled: appointmentStatus === "cancelled",
      command: onUnbook,
    },
    {
      label: "View patient",
      icon: <FontAwesomeIcon icon={faEye} size="sm" className="mr-2" />,
      command: () => navigate(`/orgs/${currentOrganizationId}/patients/${patientId}`),
    },
  ]

  const head = (
    <>
      <span title="Patient">{patientName}</span>
      {appointmentStatus && (
        <span title="Status">
          <Chip label={appointmentStatus} className="ml-2 custom-chip" />
        </span>
      )}
    </>
  )

  const details = (
    <>
      <span className="flex items-center">
        <FontAwesomeIcon icon={faCalendarDays} className="mr-1 text-gray-400" />
        <span title="Start">
          {appointmentStart
            ? formatDate(
                toZonedTime(parseISO(new Date(appointmentStart).toISOString()), "UTC"),
                formatsByTypes.LONG_DATETIME,
              )
            : "No date"}
        </span>
      </span>
      {appointmentDescription && (
        <span title="Description" className="pl-2">
          {appointmentDescription}
        </span>
      )}
      {appointmentType && (
        <span title="Type" className="font-bold capitalize pl-2" style={{ color }}>
          {appointmentType}
        </span>
      )}
    </>
  )

  return (
    <AvatarListItem
      avatarImg={<AvatarImage name={patientName} photoUrl={patientAvatar} className="h-10 w-10 rounded-full" />}
      header={head}
      subHeader={details}
      dropdownMenuItems={dropdownItems}
      onContentClick={action}
    />
  )
}

type Props = {
  appointmentStart?: Date
  appointmentDescription?: string
  appointmentStatus?: string
  patientAvatar?: string
  patientName: string
  patientId?: string
  appointmentType?: string
  color?: string
  action?(): void
  onUnbook?(): void
}

export { AgendaListItem }
