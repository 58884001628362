import { faEnvelope, faMars, faVenus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Patient, Reference, asReference, getFirstEmail, humanNameAsString } from "fhir"
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { FC, useDeferredValue, useEffect, useState } from "react"

import { usePatientsRefs } from "patients"
import { calculateAge, strCapitalize } from "utils"

const CustomAutoComplete: FC<Props> = ({
  valueReferenceId,
  label,
  className,
  horizontal,
  inputClassName,
  handleChange,
}) => {
  const [filter, setFilter] = useState<string | undefined>()
  const deferredFilter = useDeferredValue(filter)
  const enabledFetch = (deferredFilter?.length ?? 0) >= 3
  const { refetch, patientRefs } = usePatientsRefs({
    filter: enabledFetch ? deferredFilter : "",
    patientId: valueReferenceId,
  })
  const [value, setValue] = useState<Reference | undefined>()
  const [filteredItems, setFilteredItems] = useState<Patient[] | Reference[]>()

  const searchPatientsRefs = async () => {
    const { data } = await refetch()
    setFilteredItems([...(data?.patients ?? [])])
  }

  useEffect(() => {
    if (valueReferenceId && !value) {
      setValue(patientRefs?.find(({ id }) => id === valueReferenceId))
    }
  }, [valueReferenceId, patientRefs, value])

  const patientItemTemplate = (patient: Patient) => (
    <div>
      <div className="text-sm font-medium text-gray-900 mb-1 capitalize">{humanNameAsString(patient?.name?.[0])}</div>
      <div className="truncate text-xs text-gray-500 flex gap-2 divide-x divide-gray-400">
        <span>
          <span title="Age">{calculateAge(patient?.birthDate)}</span>
          {patient?.gender && (
            <FontAwesomeIcon
              icon={patient?.gender === "male" ? faMars : faVenus}
              title={strCapitalize(patient?.gender ?? "")}
              className="pl-1"
            />
          )}
        </span>
        <span title="Email" className="pl-2">
          <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
          <span>{getFirstEmail(patient?.telecom)}</span>
        </span>
      </div>
    </div>
  )

  return (
    <div
      className={classNames("field w-full", horizontal ? "inline-flex justify-between" : "flex flex-col", className)}
    >
      {label && (
        <label className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}>
          {label}
        </label>
      )}
      <div className="flex flex-col w-full">
        <AutoComplete
          suggestions={filteredItems}
          field="display"
          minLength={3}
          delay={400}
          completeMethod={searchPatientsRefs}
          onBlur={() => {
            if (!(value as Reference)?.id) {
              setFilter("")
            }
          }}
          onChange={({ value }) => {
            if (typeof value !== "string") return
            setFilter(value.trim())
            setValue({ display: value.trim() })
            handleChange?.()
          }}
          onSelect={({ value }) => {
            setValue(asReference(value))

            handleChange?.(value.id)
          }}
          value={value?.display}
          className={classNames(
            "p-inputtext-sm",
            {
              horizontal,
            },
            inputClassName,
          )}
          inputClassName={inputClassName}
          appendTo="self"
          panelClassName="w-full"
          itemTemplate={patientItemTemplate}
        />
      </div>
    </div>
  )
}

type Props = {
  valueReferenceId?: string
  label?: string
  className?: string
  horizontal?: boolean
  inputClassName?: string
  handleChange?(param?: string): void
}

export { CustomAutoComplete }
