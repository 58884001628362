import { CodeableConcept, Patient } from "fhir"
import { useFormikContext } from "formik"
import { useEffect } from "react"

import {
  addressTypes,
  BloodDrawField,
  ICD10CodesField,
  PractitionerInfo,
  PractitionerRoleDropdownField,
  ReferenceDropdownField,
  ShippingAddressField,
} from "commons"
import { getRestrictedLabPerformer, LaboratoryOrder, useAvailableLabs, useLaboratoryLocations } from "commons/labs"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BillingDropdownField } from "./BillingDropdownField"
import { LaboratoryOrderCombos } from "./LaboratoryOrderCombos"
import { LaboratoryOrderPanels } from "./LaboratoryOrderPanels"

const LaboratoryOrderForm = ({ practitioners, patient }: Props) => {
  const {
    values: {
      order: { performer, requester },
      specimenDate,
      billingType,
    },
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<LaboratoryOrder>()

  useEffect(() => {
    setFieldTouched("order.reasonCode", true)
    setFieldTouched("order.patientAddress", true)
  }, [billingType, isSubmitting, setFieldTouched])

  useEffect(() => {
    if (specimenDate) {
      setFieldValue("bloodDrawnInOffice", true)
    }
  }, [specimenDate, setFieldValue])

  const { laboratoryLocation, organizationAnIdentifier, performerLabsEnabled } = useOrganizationContext()
  const { updateLabLocation } = useLaboratoryLocations(laboratoryLocation, (locationRefs) =>
    setFieldValue("order.locationReference", locationRefs),
  )
  const { availableLabs } = useAvailableLabs(
    practitioners ?? [],
    performerLabsEnabled,
    requester,
    organizationAnIdentifier,
  )

  return (
    <div className="flex flex-col gap-8 relative">
      <div className="grid grid-cols-2 gap-4">
        <PractitionerRoleDropdownField
          field="order.requester"
          label="Practitioner"
          options={practitioners ?? []}
          useFilter={false}
        />
        <ReferenceDropdownField
          field="order.performer[0]"
          label="Laboratory"
          options={availableLabs?.map(({ ref }) => ref)}
          showFilter={false}
          handleChange={(newPerRef) => updateLabLocation(newPerRef.id)}
          assignDefaultValue={false}
        />
        <BloodDrawField />
      </div>

      <SectionName sectionName="Address" />
      <ShippingAddressField
        fieldPath="order.patientAddress"
        showLabel={false}
        defaultAddressType={addressTypes.find(({ code }) => code === "physical")}
        hideOrganizationAddress
        className="pl-6 flex-col-reverse"
      />

      <SectionName sectionName="Bill to" />
      <div className="grid grid-cols-2 gap-4">
        <BillingDropdownField field="order.insurance[0]" />
      </div>

      <SectionName sectionName={`${performer?.[0]?.display ? `${performer[0].display} ` : ""}Combos`} />
      <div className="grid grid-cols-2 gap-4">
        {performer?.[0]?.id && requester ? (
          <LaboratoryOrderCombos />
        ) : (
          <div className="flex flex-col items-center justify-center col-span-2 py-3">
            <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
            <p className="text-slate-500 text-xs pt-1">No combos found</p>
          </div>
        )}
      </div>
      <SectionName sectionName="Additional Tests" />
      <LaboratoryOrderPanels field="panels" />

      <SectionName sectionName="ICD-10" />
      <ICD10CodesField
        field="order.reasonCode"
        showLabel={false}
        showSuggestedPatientConditions
        orgSuggestedConditions="defaultProcedureIcd10"
        validate={(value?: CodeableConcept[]) => {
          const restrictedLabPerformer = getRestrictedLabPerformer(performer?.[0]?.id as string)
          return (
            (billingType === BILLING_TYPES_CODES.INSURANCE && !value?.length
              ? "Specify one condition at least"
              : undefined) ||
            (restrictedLabPerformer && performer && performer.length > 0
              ? value?.length && value?.length > restrictedLabPerformer.maxAmount
                ? `You have surpassed the maximum of allowed ICD-10 codes selectable with ${performer[0].display} (${restrictedLabPerformer.maxAmount})`
                : undefined
              : undefined)
          )
        }}
        className="pl-4"
        patient={patient}
      />
    </div>
  )
}

const SectionName = ({ sectionName }: { sectionName: string }) => (
  <div className="relative border-t">
    <span className="font-medium text-sm bg-white text-gray-500 px-2 absolute top-0 -translate-y-1/2">
      {sectionName}
    </span>
  </div>
)

type Props = {
  isEditing?: boolean
  practitioners?: PractitionerInfo[]
  labLocations: Record<string, string>
  patient: Patient
}

export { LaboratoryOrderForm }
