import { faPlus, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { isAfter, isValid, parse } from "date-fns"
import { LocationHoursOfOperationArray } from "fhir"
import { FieldArray, FieldArrayRenderProps } from "formik"
import { classNames } from "primereact/utils"

import { Button, DateField } from "commons"

const DayHours = ({ parentField, className, onRemoveDay }: Props) => {
  const parentFieldName = parentField ? `${parentField}.` : ""

  return (
    <FieldArray name={`${parentFieldName}hours`}>
      {({ name: fieldName, push, remove, form: { getFieldMeta } }: FieldArrayRenderProps) => {
        const dayHours = getFieldMeta<LocationHoursOfOperationArray[]>(fieldName).value ?? []

        const addHOP = () =>
          push<LocationHoursOfOperationArray>({
            openingTime: "08:00:00",
            closingTime: "20:00:00",
          })

        const removeHour = (hoursIndex: number) => (dayHours.length === 1 ? onRemoveDay() : remove(hoursIndex))

        const validate = ({ value, hoursIndex, isOpeningTime = false }: ValidateProps): boolean => {
          if (!value) return false
          const date = parse(value, "HH:mm:ss", new Date())
          const startTime = isOpeningTime
            ? date
            : parse(dayHours[hoursIndex]?.openingTime ?? "", "HH:mm:ss", new Date())
          const endTime = !isOpeningTime ? date : parse(dayHours[hoursIndex]?.closingTime ?? "", "HH:mm:ss", new Date())

          if (isValid(startTime) && isValid(endTime) && isAfter(endTime, startTime)) {
            if (isOpeningTime && hoursIndex > 0) {
              const _prevClose = parse(dayHours[hoursIndex - 1]?.closingTime ?? "", "HH:mm:ss", new Date())
              return !isValid(_prevClose) || isAfter(startTime, _prevClose)
            } else if (!isOpeningTime && hoursIndex < dayHours.length - 1) {
              const _nextOpen = parse(dayHours[hoursIndex + 1]?.openingTime ?? "", "HH:mm:ss", new Date())
              return !isValid(_nextOpen) || isAfter(_nextOpen, endTime)
            }
            return true
          }
          return false
        }

        return (
          <div className={classNames("flex flex-col", className)}>
            {dayHours.map((_, hoursIndex) => (
              <div key={hoursIndex} className="flex gap-4">
                <DateField
                  label={hoursIndex === 0 ? "Opening time" : undefined}
                  field={`${parentFieldName}hours[${hoursIndex}].openingTime`}
                  className="text-xs"
                  timeOnly
                  validation={(value) => !validate({ value, hoursIndex, isOpeningTime: true }) && "Invalid value"}
                />
                <DateField
                  label={hoursIndex === 0 ? "Closing time" : undefined}
                  field={`${parentFieldName}hours[${hoursIndex}].closingTime`}
                  className="text-xs"
                  timeOnly
                  validation={(value) => !validate({ value, hoursIndex }) && "Invalid value"}
                />
                <div className={classNames("flex flex-col gap-2", { "pt-7": hoursIndex === 0 })}>
                  <Button
                    icon={faTrashCan}
                    buttonStyle="outlined"
                    className="h-10 w-10 justify-center"
                    iconClassName="h-4 w-4"
                    onClick={() => removeHour(hoursIndex)}
                  />
                  {hoursIndex === dayHours.length - 1 && (
                    <Button icon={faPlus} buttonStyle="outlined" className="h-10 w-10" onClick={addHOP} />
                  )}
                </div>
              </div>
            ))}
          </div>
        )
      }}
    </FieldArray>
  )
}

type Props = {
  parentField?: string
  className?: string
  onRemoveDay(): void
}

type ValidateProps = {
  value: string
  hoursIndex: number
  isOpeningTime?: boolean
}

export { DayHours }
