import { IconDefinition, faExternalLink, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { faCircleCheck, faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"
import { MouseEvent, ReactNode } from "react"

import { CardClickableArea } from "../types"
import { Badge, BadgeProps } from "./Badge"

const CardSelectionItem = ({
  mainText,
  mainData,
  subTexts,
  extraDetails,
  rightData,
  rightDataClassName,
  selected,
  showSelectedMark = true,
  disabled,
  disabledBadgeProps,
  onSelect,
  onShowDetails,
  footer,
  clickableArea = "right-corner",
  externalAction,
  headerClassName,
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        "relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none transition-all ease-in-out",
        { "border-transparentX ring-1 ring-primary": selected },
        { "border-gray-500": !selected && !disabled },
        { "border-gray-200": disabled },
        { "cursor-pointer hover:shadow-md": !disabled },
        className,
      )}
      onClick={clickableArea === "card" && !disabled ? onSelect : undefined}
    >
      <div className="flex flex-col grow flex-1 overflow-hidden ring-0">
        <div className="flex flex-1 whitespace-nowrap w-full">
          <div className="flex flex-col grow w-full">
            <div
              className={classNames(
                "flex flex-1 space-x-1 font-medium text-sm items-center",
                headerClassName,
                !disabled ? "text-gray-600" : "text-gray-200",
              )}
            >
              <div
                className="text-ellipsis line-clamp-2 overflow-hidden break-words whitespace-normal"
                title={mainText}
              >
                {mainData ?? mainText}
              </div>

              {!!externalAction && (
                <FontAwesomeIcon
                  icon={externalAction.loading ? faSpinner : externalAction.icon ?? faExternalLink}
                  spin={externalAction.loading}
                  title={externalAction.title}
                  className="cursor-pointer ml-3"
                  onClick={(e) => {
                    e.stopPropagation()
                    externalAction?.onClick(e)
                  }}
                />
              )}
            </div>
            {subTexts?.map((text, index) => (
              <span
                key={`st${index}`}
                className={classNames("mt-1 flex items-center text-sm", !disabled ? "text-gray-600" : "text-gray-200")}
              >
                {text}
              </span>
            ))}
            {extraDetails}
          </div>
          <div
            className="flex flex-col space-y-2 flex-1 items-end"
            onClick={clickableArea === "right-corner" && !disabled ? onSelect : undefined}
          >
            {showSelectedMark && !disabled && (
              <span className="inline-flex justify-end items-start">
                {!!selected && <FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5 text-primary" />}
              </span>
            )}
            {disabled && disabledBadgeProps && <Badge {...disabledBadgeProps} />}
            {onShowDetails && (
              <FontAwesomeIcon
                icon={faCircleInfo}
                title="Show details"
                className="h-5 w-5 text-primary cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  onShowDetails()
                }}
              />
            )}
            {rightData && (
              <span
                className={classNames(
                  "font-medium text-sm",
                  rightDataClassName,
                  !disabled ? "text-gray-800" : "text-gray-200",
                )}
              >
                {rightData}
              </span>
            )}
          </div>
        </div>

        <span
          className={classNames(
            "pointer-events-none absolute -inset-px rounded-lg",
            selected ? "border border-primary" : "border-2 border-transparent",
          )}
        />
        {footer}
      </div>
    </div>
  )
}

type Props = {
  mainText: string
  mainData?: ReactNode
  subTexts?: string[]
  extraDetails?: ReactNode | string
  rightData?: string | ReactNode
  rightDataClassName?: string
  selected?: boolean
  showSelectedMark?: boolean
  disabled?: boolean
  disabledBadgeProps?: BadgeProps
  onSelect(): void
  onShowDetails?(): void
  footer?: ReactNode
  clickableArea?: CardClickableArea
  externalAction?: {
    loading?: boolean
    icon?: IconDefinition
    title?: string
    onClick(event: MouseEvent): Promise<void> | void
  }
  headerClassName?: string
  className?: string
}

export { CardSelectionItem }
