import { asReference } from "fhir"
import { FormikHelpers } from "formik"
import { FC, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { MC_ACTIVITY_TYPE } from "data"
import { useOpenEncounter } from "encounter"
import { NotFoundView } from "errors"
import { CPOE_STEPS, useCPOEContext, useUpdateAllCpoeRequests } from "orders"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { displayNotificationSuccess, strCapitalize } from "utils"

import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { PanelQuestionnairesModal } from "../../../forms/labFields/PanelQuestionnairesModal"
import { QuestionnaireData } from "../../../types"
import { getPlanInitialValues, sanitizeCP } from "../../components/validations"
import {
  useActivateCarePlan,
  useCancelPlan,
  useCarePlans,
  usePlanContext,
  useTestQuestionnaires,
  useUpdatePlan,
} from "../../hooks"
import { PLAN_SUBVIEWS, PlanContext, PlanData } from "../../types"
import { getContextLabel } from "../../utils"
import { PlanSectionedForm } from "./PlanSectionedForm"

const Planconfiguration: FC = () => {
  const chartContext = useChartContext()
  const { patientId, patient, navigateToSubview, returnToListView } = usePlanContext()
  const { currentOrganizationId, currentOrganization } = useOrganizationContext()
  const { organizationPractitionersInfo, isLoading: isLoadingPracts } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })

  const { openEncounterRef } = useOpenEncounter(patientId)
  const cpoeCtx = useCPOEContext()
  const [params] = useSearchParams()

  const [planToActivate, setPlanToActivate] = useState<string>()
  const [questionnairesToAnswer, setQuestionnairesToAnswer] = useState<string[]>()
  const [formHelpers, setFormHelpers] = useState<FormikHelpers<PlanData>>()
  const [isSurveyOnly, setIsSurveyOnly] = useState(false)
  const [activationConfirmed, setActivationConfirmed] = useState(false)
  const [cancelConfirm, setCancelConfirm] = useState(false)

  const planId = params.get("planId") ?? undefined
  const { plans, isLoading } = useCarePlans({
    patientId,
    planId,
  })

  useEffect(() => {
    chartContext.setSearchData({ showSearch: false })
  }, [])

  const { questionnaires, count: qsCount, isLoading: isLoadingQs } = useTestQuestionnaires(questionnairesToAnswer)

  const { updatePlan, isUpdating } = useUpdatePlan(
    () => {
      if (!planToActivate) {
        displayNotificationSuccess(`${strCapitalize(contextLabel)} updated successfully!`)
        navigateToSubview()
      }
    },
    (error) => {
      if (error) {
        setPlanToActivate(undefined)
        navigateToSubview()
      } else if (planToActivate) activateCarePlan(planToActivate)
    },
  )
  const savePlanConfig = (data: PlanData, formHelpers?: FormikHelpers<PlanData>) => {
    if (planToActivate && !activationConfirmed) {
      setFormHelpers(formHelpers)
      const surveyOnly = data?.mcActivity?.detail?.kind === MC_ACTIVITY_TYPE.MC_SURVEY
      if (surveyOnly) {
        setIsSurveyOnly(surveyOnly)
      } else if (data.bloodDrawnInOffice && data.questionnairesCanonicals?.length) {
        setQuestionnairesToAnswer(data.questionnairesCanonicals)
      }
    } else {
      const { contained, activity, encounter } = sanitizeCP(
        { ...data },
        asReference(patient),
        asReference(currentOrganization),
        data.combos,
        openEncounterRef,
      )

      updatePlan({
        ...data,
        carePlan: {
          ...data.carePlan,
          activity,
          contained,
          encounter,
        },
      })
    }
  }
  const { updateAllCpoeRequests, isUpdatingAllRequests } = useUpdateAllCpoeRequests(patientId, () =>
    cpoeCtx.showCpoeOverlay(CPOE_STEPS.FINISH),
  )

  const { activateCarePlan, isActivating } = useActivateCarePlan(
    (planId, planResources) => {
      if (
        !isSurveyOnly &&
        planResources.some(({ resourceType }) =>
          ["ServiceRequest", "MedicationRequest", "Procedure"].includes(resourceType as string),
        )
      ) {
        updateAllCpoeRequests(planResources)
      } else navigateToSubview(PLAN_SUBVIEWS.DETAILS, planId)
    },
    () => {
      setPlanToActivate(undefined)
      setQuestionnairesToAnswer(undefined)
    },
  )

  const planToConfigure = plans?.[0]

  const initialValues = useMemo(
    () => getPlanInitialValues(patient, planToConfigure, openEncounterRef),
    [openEncounterRef, patient, planToConfigure],
  )

  const contextLabel = useMemo(() => getContextLabel(params.get("context") as PlanContext), [params])

  const { cancelPlan, isCanceling } = useCancelPlan(() => {
    setCancelConfirm(false)
    navigateToSubview()
  })

  const handleCancel = () => {
    cancelPlan(planId as string)
  }

  if (isLoading || isLoadingPracts) return <SkeletonLoader loaderType="form-two-cols" repeats={1} />
  if (!plans.length) return <NotFoundView />

  const handleCancelActivate = () => {
    setPlanToActivate(undefined)
    setQuestionnairesToAnswer(undefined)
    if (formHelpers) formHelpers.setSubmitting(false)
  }

  const handleSaveQRs = (qData: QuestionnaireData[]) => {
    setActivationConfirmed(true)
    if (formHelpers) {
      formHelpers.setFieldValue("questionnairesAnswered", qData)
      formHelpers.submitForm()
    }
  }

  const handleActivate = () => {
    if (formHelpers) {
      setActivationConfirmed(true)
      formHelpers.submitForm()
    }
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <PlanSectionedForm
        initialValues={initialValues}
        onSubmit={savePlanConfig}
        onClose={returnToListView}
        contextLabel={contextLabel}
        handleActivate={setPlanToActivate}
        handleCancel={handleCancel}
        practitionersInfo={organizationPractitionersInfo}
        isCanceling={isCanceling}
        isProcessingAction={isUpdating || isLoadingQs || isActivating || isUpdatingAllRequests}
        openEncounter={openEncounterRef}
        patient={patient}
      />

      {(!questionnairesToAnswer?.length || (!isLoadingQs && !qsCount)) && (
        <ConfirmDialog
          confirmText={`Are you sure you want to activate this ${contextLabel}?`}
          visible={!!planToActivate || isActivating}
          isLoading={isActivating || activationConfirmed}
          onConfirm={handleActivate}
          waitToFinish
          hideDialog={handleCancelActivate}
        />
      )}

      {!!qsCount && (
        <PanelQuestionnairesModal
          panels={[{ questionnaires }]}
          onSave={handleSaveQRs}
          onHide={handleCancelActivate}
          isSaving={isUpdating || isActivating}
        />
      )}

      <ConfirmDialog
        visible={cancelConfirm}
        confirmText={`Are you sure you want to cancel this ${contextLabel}?`}
        isLoading={isCanceling}
        waitToFinish
        onConfirm={handleCancel}
        hideDialog={() => setCancelConfirm(false)}
      />
    </div>
  )
}

export { Planconfiguration }
