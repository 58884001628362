import { FC } from "react"

import { InputField, NumberField, SwitchField } from "commons"

import { MedicationField } from "./MedicationField"

const InventoryForm: FC<Props> = ({ isEditing, mksOnInventoryCodes }) => (
  <div className="flex flex-col space-y-4">
    {isEditing ? (
      <InputField field="med.textDisplayedInField" disabled={isEditing} />
    ) : (
      <MedicationField field="med" label="Medication" validateDuplicate mksOnInventoryCodes={mksOnInventoryCodes} />
    )}
    <div className="flex gap-4">
      <NumberField field="netContent.value" label="Available quantity" min={0} />
      {!!isEditing && (
        <SwitchField field="status" className="flex w-1/2" trueValue="active" falseValue="inactive" label="Active" />
      )}
    </div>
  </div>
)

type Props = { isEditing?: boolean; mksOnInventoryCodes?: string[] }

export { InventoryForm }
