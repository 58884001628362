import { Appointment } from "fhir"
import { useParams } from "react-router-dom"

import { DialogFormContainer } from "commons"
import { useOrganizationContext } from "organization"
import { useLoginContext } from "security"

import { AppointmentForm } from "./AppointmentForm"
import { appointmentValidationSchema, initialValues } from "./validations"

const AppointmentFormOverlay = ({ appointment, onSubmit, onHide, visible, isEditing }: Props) => {
  const { patientId } = useParams()
  const { loggedInPractitioner } = useLoginContext()
  const { location } = useOrganizationContext()

  return (
    <DialogFormContainer
      title={`${isEditing ? "Update" : "Schedule"} Appointment`}
      showForm={!!visible}
      initialValue={initialValues(appointment, loggedInPractitioner, location)}
      useFormik
      validationSchema={appointmentValidationSchema}
      onSubmit={onSubmit}
      onCancel={onHide}
      enableReinitialize
      className="w-4/5 md:w-4/5 lg:w-4/5 xl:w-2/3"
      saveLabel={isEditing ? "Update" : "Schedule"}
    >
      <AppointmentForm hidePatientField={patientId !== undefined} isEditing={isEditing} />
    </DialogFormContainer>
  )
}

type Props = {
  visible?: boolean
  isEditing?: boolean
  appointment: Appointment
  onHide(): void
  onSubmit(appointment: Appointment): void
}

export { AppointmentFormOverlay }
