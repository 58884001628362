import { isEqual, isSameDay, parseISO } from "date-fns"
import { toZonedTime } from "date-fns-tz"
import { FieldProps, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC, useEffect, useMemo } from "react"

import { FormField } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { useFindAvailableSlots, useScheduleAppointmentContext } from "../hooks"
import { AppointmentFormData } from "../types"

const AppointmentSlotsSection: FC = () => {
  const {
    selectedMonthBounds: { start: startDate, end: endDate },
    selectedDate,
  } = useScheduleAppointmentContext()
  const {
    values: { participant, start, selectedSlot },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const healthcareServiceReference = participant?.[5]?.actor

  const { slots, isLoading } = useFindAvailableSlots({
    healthcareService: healthcareServiceReference,
    startDate,
    endDate,
    patient: participant?.[0]?.actor,
    practitioner: participant?.[1]?.actor,
  })

  const currentDaySlots = useMemo(
    () => (selectedDate ? slots.filter(({ start }) => isSameDay(start, selectedDate)) : []),
    [selectedDate, slots],
  )

  useEffect(() => {
    setFieldValue("start", selectedSlot?.start)
    setFieldValue("end", selectedSlot?.end)
  }, [selectedSlot])

  return (
    <fieldset className="disabled:opacity-40 disabled:pointer-events-none" disabled={!currentDaySlots.length}>
      <FormField field="selectedSlot" label="Pick time">
        {({ field: { name, value }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => (
          <div className="grid grid-cols-2 gap-4">
            {currentDaySlots.length
              ? currentDaySlots.map((slot, index) => (
                  <span
                    key={`slot-${index}`}
                    className={classNames(
                      "p-component p-inputtext p-inputtext-sm h-[38.75px] justify-center cursor-pointer",
                      {
                        "border-primary bg-light-primary text-primary font-semibold":
                          start && isEqual(value.start, slot.start),
                        "border p-invalid": touched && error,
                      },
                    )}
                    role="button"
                    onClick={() => {
                      setFieldValue(name, slot)
                    }}
                  >
                    {formatDate(toZonedTime(parseISO(new Date(slot.start).toISOString()), "UTC"), formatsByTypes.TIME)}
                  </span>
                ))
              : Array.from({ length: 14 }).map((_, index) => (
                  <span
                    key={`skeleton-${index}`}
                    className={classNames("p-component p-inputtext p-inputtext-sm h-[38.75px] pointer-events-none", {
                      "bg-gray-300 animate-pulse border-none": isLoading,
                      "border p-invalid": touched && error,
                    })}
                  />
                ))}
          </div>
        )}
      </FormField>
    </fieldset>
  )
}

export { AppointmentSlotsSection }
