export const telemedicineServiceCC = {
  text: "Telemedicine Service",
  coding: [
    {
      code: "telemedicine",
      system: "http://hl7.org/fhir/virtual-service-type",
      display: "Telemedicine",
    },
  ],
}
