import { useFormikContext } from "formik"
import { FC, PropsWithChildren, useState } from "react"

import { ScheduleAppointmentContext } from "../hooks"
import { AppointmentFormData, MonthBounds } from "../types"
import { getMonthDateBounds } from "../utils"

const currentDate = new Date()

//TODO: maybe this provider is not necessary, we could use formik context
const ScheduleAppointmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    values: { start },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const [selectedMonthBounds, setSelectedMonthBounds] = useState<MonthBounds>(
    getMonthDateBounds({ month: currentDate.getMonth(), year: currentDate.getFullYear() }),
  )
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(start && new Date(start))

  const updateSelectedMonthBounds = ({ start, end }: { start: Date; end: Date }) => {
    setSelectedMonthBounds({ start, end })
    setSelectedDate(undefined)
  }

  const updateSelectedDate = (date?: Date) => {
    setFieldValue("selectedSlot", undefined)
    setSelectedDate(date)
  }

  return (
    <ScheduleAppointmentContext.Provider
      value={{
        selectedMonthBounds,
        updateSelectedMonthBounds,
        selectedDate,
        updateSelectedDate,
      }}
    >
      {children}
    </ScheduleAppointmentContext.Provider>
  )
}

export { ScheduleAppointmentProvider }
