import { faCircleNotch, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import { faChevronDown, faChevronUp, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, codeableConceptAsString, isPatient } from "fhir"
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from "formik"
import isEqual from "lodash/isEqual"
import pluralize from "pluralize"
import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { ReactHTML, useEffect, useState } from "react"

import { ModulesId, TextAreaField } from "commons"
import {
  MedicationKnowledgeImage,
  MedicationRequestData,
  MedicationRequestInfo,
  MedicationRequestItem,
  dispenseInterval,
  treatmentFrequencies,
  useMedicationAvailability,
} from "commons/meds"
import { useAppModuleContext } from "internals"
import { getCommonCode } from "utils"

import { getDraftValues, getRecommendedUnitsForTreatment } from "../validations"
import { MedicationRequestUnitsDropdown } from "./MedicationRequestUnitsDropdown"

const MedicationRequestCardForm = ({
  medicationData: { medicationRequestInfo, medicationKnowledge, productPrice },
  isDeleting,
  isSaving,
  onSave,
  onDelete,
  onSelectMK,
  className = "shadow-md border border-slate-300 rounded-lg",
  imageClassName = "h-24",
  customTag,
  collapsibleForm = false,
  variant = "default",
}: Props) => {
  const { isModuleActive } = useAppModuleContext()
  const [isTyping, setIsTyping] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(collapsibleForm)
  const toogleCollapse = () => setShowCollapsed((state) => !state)
  const { isLoadingAvailability, medAvailability, medQuantityAvailable, refetchMedAvailability } =
    useMedicationAvailability(
      getCommonCode({ codes: medicationKnowledge?.code?.coding }),
      medicationRequestInfo?.dispenseRequest?.initialFill?.quantity?.value ?? 1,
    )

  const Tag = customTag ?? Form

  const MRForm = ({ setFieldValue, initialValues, values, submitForm }: FormikProps<MedicationRequestInfo>) => {
    const defaultAutoship =
      initialValues?.dispenseRequest?.dispenseInterval?.unit !== dispenseInterval[0].value.unit ||
      initialValues?.dispenseRequest?.dispenseInterval?.value !== dispenseInterval[0].value.value

    const recommendedDispenseUnits = getRecommendedUnitsForTreatment(
      medicationKnowledge?.amount?.value ?? 1,
      medicationRequestInfo.dosageInstruction?.[0],
      medicationRequestInfo.dispenseRequest?.dispenseInterval,
    )
    const orientedDispenseUnits = medicationRequestInfo.dispenseRequest?.quantity?.value ?? 1
    const showUnitsWarning = recommendedDispenseUnits > orientedDispenseUnits

    const prescriptionWarnMessage = `The specified units of ${codeableConceptAsString(
      medicationRequestInfo.medication?.CodeableConcept,
    )} are not enough to comply with the targeted treatment. Consider increasing the units to ${recommendedDispenseUnits}.`

    const [autoship, setAutoship] = useState(defaultAutoship)
    const [isDFO, setIsDFO] = useState(initialValues?.dispenseRequest?.initialFill?.isDfo ?? false)
    const [warningAvailability, setWarningAvailability] = useState(!medQuantityAvailable)

    useEffect(() => {
      if (!isEqual(initialValues, values) && !isTyping) {
        submitForm()
      }
    }, [values, isTyping])

    useEffect(() => {
      if (!medQuantityAvailable && !isSaving) {
        setFieldValue("dispenseRequest.initialFill.isDfo", false)
        setIsDFO(false)
        setWarningAvailability(true)
      }
    }, [medQuantityAvailable, isSaving])

    const onKeyDown = () => {
      setIsTyping(true)
    }

    const onBlur = () => {
      setIsTyping(false)
    }

    const updateAutoship = (state: boolean) => {
      if (!state) {
        setFieldValue("dispenseRequest.numberOfRepeatsAllowed", 0)
        setFieldValue("dispenseRequest.dispenseInterval", dispenseInterval[0].value)
      } else {
        setFieldValue("dispenseRequest.numberOfRepeatsAllowed", undefined)
        setFieldValue(
          "dispenseRequest.dispenseInterval",
          defaultAutoship ? initialValues?.dispenseRequest?.dispenseInterval : dispenseInterval[1].value,
        )
      }
      setAutoship(state)
    }

    const showPrescriptionWarning = (target: HTMLElement) => {
      confirmPopup({
        target,
        message: prescriptionWarnMessage,
        icon: "pi pi-exclamation-triangle",
        acceptClassName: "button-primary",
        rejectClassName: "button-default",
        style: { width: "25vw" },
        accept: () => setFieldValue("dispenseRequest.initialFill.quantity.value", recommendedDispenseUnits),
      })
    }

    const updateDFO = async (state: boolean) => {
      setIsDFO(state)
      if (state) {
        const { data } = await refetchMedAvailability()
        setWarningAvailability(!data?.medQuantityAvailable)
        data?.medQuantityAvailable ? setFieldValue("dispenseRequest.initialFill.isDfo", true) : setIsDFO(false)
      } else setFieldValue("dispenseRequest.initialFill.isDfo", false)
    }

    const locked = isSaving || isDeleting || isLoadingAvailability

    return (
      <Tag
        className={classNames(
          "flex flex-col relative p-3 mr-card min-w-fit",
          { "gap-y-6": variant === "simple" },
          className,
          medicationRequestInfo.id,
        )}
      >
        <div className="product-list-item flex h-full">
          <div className="flex flex-1 flex-wrap">
            <div
              role="button"
              className={classNames("cursor-pointer", {
                "rounded-md w-[4.25rem] h-[4.25rem] flex items-center justify-center border border-solid border-gray-200 bg-gray-100":
                  variant === "simple",
              })}
              onClick={() => {
                onSelectMK(medicationKnowledge)
              }}
            >
              <MedicationKnowledgeImage
                drugCharacteristic={medicationKnowledge?.drugCharacteristic}
                className={imageClassName}
              />
            </div>

            <div className="flex flex-col gap-2">
              <MedicationRequestItem medicationKnowledge={medicationKnowledge ?? {}} className="ml-2 xl:ml-3" />
              {variant === "default" && (
                <span
                  className={classNames("ml-2 xl:ml-3 inline-flex items-center transition-all ease-in-out", {
                    hidden: showCollapsed,
                  })}
                >
                  <MedicationRequestUnitsDropdown
                    field="dispenseRequest.initialFill.quantity.value"
                    optionUnits={Array.from({ length: 20 }, (_, i) => i + 1)}
                    unitName={medicationRequestInfo.dispenseRequest?.quantity?.unit}
                    price={productPrice ?? medicationKnowledge?.cost?.[0].cost}
                    disabled={locked}
                  />
                  {showUnitsWarning && (
                    <span
                      id={`warning${medicationRequestInfo.id}`}
                      className={classNames({ "p-disabled": locked })}
                      data-pr-tooltip="Warning: Units are not enough to comply with the targeted treatment. Click here to take action."
                      onClick={(e) => {
                        e.stopPropagation()
                        !isSaving && !isDeleting && showPrescriptionWarning(e.currentTarget)
                      }}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 cursor-pointer text-lg" />
                      <ConfirmPopup key={medicationRequestInfo.id} />
                      <Tooltip target={`#warning${medicationRequestInfo.id}`} position="bottom" />
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>

          <div className={classNames("flex justify-end", { "h-6": variant === "simple" })}>
            <div
              className={classNames("flex", {
                "flex-row-reverse space-x-8": variant === "simple",
                "flex-col space-y-5": variant === "default",
              })}
            >
              {collapsibleForm && (
                <FontAwesomeIcon
                  icon={showCollapsed ? faChevronDown : faChevronUp}
                  size="sm"
                  onClick={toogleCollapse}
                  className={classNames("cursor-pointer", {
                    "text-gray-400 self-center": variant === "simple",
                    "self-end": variant === "default",
                  })}
                />
              )}
              <div
                className={classNames("flex flex-col justify-between space-y-3 transition-all ease-in-out", {
                  hidden: showCollapsed,
                })}
              >
                {onDelete && (
                  <div
                    className={classNames("product-list-action flex flex-col", {
                      "gap-1 lg:gap-2": variant === "default",
                    })}
                  >
                    {variant === "simple" ? (
                      <span
                        className={classNames(
                          "mx-1 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:border hover:border-gray-200 w-6 h-6 mr-8",
                          { "cursor-pointer": !locked },
                        )}
                        title={isSaving ? "Saving" : "Remove"}
                        onClick={() => {
                          onDelete(medicationRequestInfo?.id as string)
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} className="fa-fw" />
                      </span>
                    ) : (
                      <Button
                        label={isSaving ? "Saving" : "Remove"}
                        type="button"
                        icon={<FontAwesomeIcon icon={faTrashCan} size="1x" className="mr-1" />}
                        className="button-default p-button-sm"
                        loading={isSaving || isDeleting}
                        disabled={locked}
                        onClick={() => {
                          onDelete(medicationRequestInfo?.id as string)
                        }}
                      />
                    )}
                  </div>
                )}
                {isPatient(medicationRequestInfo.requester) && (
                  <span className="text-orange-400 text-xs truncate">Requested by patient</span>
                )}
                {variant === "default" && (
                  <>
                    <div className="flex justify-between items-center gap-2">
                      <span className="text-sm">Autoship</span>
                      <InputSwitch checked={autoship} onChange={(e) => updateAutoship(e.value)} disabled={locked} />
                    </div>
                    {isModuleActive(ModulesId.CHECKOUT_INVENTORY) && (
                      <div className="flex justify-between items-center gap-2">
                        <span className="text-sm inline-flex items-center" title="Dispense from office">
                          DFO
                          {isDFO && isLoadingAvailability && (
                            <FontAwesomeIcon icon={faCircleNotch} spin className="ml-2" />
                          )}
                          {!medQuantityAvailable && (warningAvailability || isDFO) && !isLoadingAvailability && (
                            <span
                              id={`warningAvailability${medicationRequestInfo.id}`}
                              className={classNames({ "p-disabled": locked })}
                              data-pr-tooltip={`Warning: There is no availability to dispense this medication from office.
                          ${
                            medAvailability
                              ? `There is only ${medAvailability} ${pluralize(medicationRequestInfo.dispenseRequest?.quantity?.unit?.toLowerCase() as string, medAvailability)} available.`
                              : ""
                          }`}
                            >
                              <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg" />
                              <Tooltip target={`#warningAvailability${medicationRequestInfo.id}`} position="bottom" />
                            </span>
                          )}
                        </span>
                        <InputSwitch checked={isDFO} onChange={(e) => updateDFO(e.value)} disabled={locked} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames("flex @lg:flex-row gap-2 mt-3 flex-wrap transition-all ease-in-out", {
            hidden: showCollapsed,
          })}
        >
          {variant === "simple" && (
            <div className="flex-1 flex flex-col">
              <label className="text-sm font-medium text-gray-500 mb-2">Quantity</label>
              <div
                className={classNames("flex items-center transition-all ease-in-out", {
                  hidden: showCollapsed,
                })}
              >
                <MedicationRequestUnitsDropdown
                  field="dispenseRequest.initialFill.quantity.value"
                  optionUnits={Array.from({ length: 20 }, (_, i) => i + 1)}
                  unitName={medicationRequestInfo.dispenseRequest?.quantity?.unit}
                  price={productPrice ?? medicationKnowledge?.cost?.[0].cost}
                  className="flex-1"
                  slashed={false}
                  disabled={locked}
                />
                {showUnitsWarning && (
                  <span
                    id={`warning${medicationRequestInfo.id}`}
                    className={classNames({ "p-disabled": locked })}
                    data-pr-tooltip="Warning: Units are not enough to comply with the targeted treatment. Click here to take action."
                    onClick={(e) => {
                      e.stopPropagation()
                      !isSaving && !isDeleting && showPrescriptionWarning(e.currentTarget)
                    }}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 cursor-pointer text-lg" />
                    <ConfirmPopup key={medicationRequestInfo.id} />
                    <Tooltip target={`#warning${medicationRequestInfo.id}`} position="bottom" />
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="flex flex-col flex-1">
            <label
              htmlFor="doseQuantity"
              className={classNames("text-sm font-medium mb-2", {
                "text-gray-500": variant === "simple",
                "text-gray-700": variant === "default",
              })}
            >
              Dosage
            </label>
            <div
              className={classNames("p-inputgroup product-dosage flex items-center relative", {
                "flex-1": variant === "simple",
              })}
            >
              <Field
                title="Units"
                name="doseQuantity"
                validate={(value: string) => (/^\d(-\d)?$/.test(value) ? undefined : "Dose quantity is invalid.")}
              >
                {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
                  <InputText
                    type="text"
                    id={name}
                    name={name}
                    onChange={onChange}
                    value={value}
                    className={classNames("p-inputtext-sm max-w-min min-w-[3.2rem] text-center", {
                      "p-invalid": touched && error,
                    })}
                    disabled={locked}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                  />
                )}
              </Field>
              <ErrorMessage name="doseQuantity">
                {(msg) => <small className="p-error absolute -bottom-6">{msg}</small>}
              </ErrorMessage>
              <span className="p-inputtext-sm text-sm capitalize justify-center flex px-2 items-center max-w-max min-w-[4.5rem] cursor-default border border-slate-300">
                {medicationRequestInfo.medicationUnit}
              </span>
              <Field title="Frequency" name="treatmentFrequency">
                {({ field: { name, value, onChange } }: FieldProps) => (
                  <Dropdown
                    options={treatmentFrequencies}
                    optionLabel="label"
                    optionValue="value"
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    className={classNames("p-inputtext-sm items-center small-trigger min-w-[9rem]", {
                      "max-w-min": variant === "default",
                    })}
                    disabled={locked}
                  />
                )}
              </Field>
              {/* <Field title="Duration" name="dispenseRequest.initialFill.duration">
              {({ field: { name, value, onChange } }: FieldProps) => (
                <Dropdown
                  options={treatmentDurations}
                  optionLabel="label"
                  optionValue="value"
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  className="p-inputtext-sm small-trigger"
                  disabled={isSaving}
                />
              )}
            </Field> */}
            </div>
          </div>

          <div className="flex flex-col flex-1">
            <label
              htmlFor="autoship"
              className={classNames("text-sm font-medium mb-2", {
                "text-gray-500": variant === "simple",
                "text-gray-700": variant === "default",
              })}
            >
              Dispense
            </label>
            <Field title="Interval" name="dispenseRequest.dispenseInterval">
              {({ field: { name, value, onChange } }: FieldProps) => (
                <Dropdown
                  options={autoship ? dispenseInterval.slice(1) : dispenseInterval}
                  optionLabel="label"
                  optionValue="value"
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  className="p-inputtext-sm items-center flex-1 small-trigger"
                  disabled={locked || !autoship}
                />
              )}
            </Field>
          </div>
          {variant === "simple" && (
            <div className="flex flex-1 flex-row items-stretch pt-6 gap-x-6">
              <div className="flex justify-between items-center gap-2">
                <Checkbox checked={autoship} onChange={(e) => updateAutoship(e.value)} disabled={locked} />
                <span className="text-sm text-gray-500">Autoship</span>
              </div>
              <div className="flex justify-between items-center gap-2">
                <Checkbox checked={isDFO} onChange={(e) => updateDFO(e.value)} disabled={locked} />
                <span className="text-sm inline-flex items-center text-gray-500" title="Dispense from office">
                  DFO
                  {isDFO && isLoadingAvailability && <FontAwesomeIcon icon={faCircleNotch} spin className="ml-2" />}
                  {!medQuantityAvailable && (warningAvailability || isDFO) && !isLoadingAvailability && (
                    <span
                      id={`warningAvailability${medicationRequestInfo.id}`}
                      className={classNames({ "p-disabled": locked })}
                      data-pr-tooltip={`Warning: There is no availability to dispense this medication from office.
                          ${
                            medAvailability
                              ? `There is only ${medAvailability} ${pluralize(medicationRequestInfo.dispenseRequest?.quantity?.unit?.toLowerCase() as string, medAvailability)} available.`
                              : ""
                          }`}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-lg fa-fw" />
                      <Tooltip target={`#warningAvailability${medicationRequestInfo.id}`} position="bottom" />
                    </span>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames("flex flex-col gap-2 transition-all ease-in-out", {
            hidden: showCollapsed,
            "mt-4": variant === "default",
          })}
        >
          <TextAreaField
            field="note[0].text"
            rows={2}
            className="p-fluid w-full"
            labelClassName={classNames({
              "!text-gray-500": variant === "simple",
              "!text-gray-700": variant === "default",
            })}
            inputClassName="resize-none min-h-[6.5625rem]"
            label="Additional instructions"
            placeholder="(empty)"
            disabled={locked}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          />
        </div>
      </Tag>
    )
  }

  return (
    <Formik
      key={medicationRequestInfo.id}
      enableReinitialize
      initialValues={getDraftValues(medicationRequestInfo)}
      onSubmit={onSave}
    >
      {MRForm}
    </Formik>
  )
}

type Props = {
  medicationData: MedicationRequestData
  isDeleting?: boolean
  isSaving?: boolean
  onSave(medInfo: MedicationRequestInfo): void
  onDelete?(medicationRequestId: string): void
  onSelectMK(_?: MedicationKnowledge): void
  className?: string
  imageClassName?: string
  customTag?: keyof ReactHTML
  collapsibleForm?: boolean
  variant?: "default" | "simple"
}

export { MedicationRequestCardForm }
