import { FormikValues, useFormikContext } from "formik"
import { FC } from "react"

import { CheckBoxField } from "../CheckBoxField"
import { DateField } from "../DateField"
import { FormFieldBaseProps } from "../FormField"

const BloodDrawField: FC<Props> = ({
  handleUpdateBDIO,
  handleUpdateSpecimenDate,
  label = "Draw blood in office",
  checkboxClassName,
  ...formFieldProps
}) => {
  const {
    values: { bloodDrawnInOffice },
  } = useFormikContext<FormikValues & { bloodDrawnInOffice: boolean }>()

  return (
    <>
      <CheckBoxField
        field="bloodDrawnInOffice"
        label={label}
        onCheck={handleUpdateBDIO}
        className={checkboxClassName}
        labelClassName="!text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
      />
      <DateField
        field="specimenDate"
        onChange={handleUpdateSpecimenDate}
        showTime
        disabled={!bloodDrawnInOffice}
        label="Specimen Collection Date"
        validation={(value) => (!value ? "Specimen date is required" : undefined)}
        {...formFieldProps}
      />
    </>
  )
}

type Props = {
  label?: string
  checkboxClassName?: string
  handleUpdateBDIO?(bdio: boolean): void
  handleUpdateSpecimenDate?(sDate: Date): void
} & Omit<FormFieldBaseProps, "validation" | "field">

export { BloodDrawField }
