import { InventoryItem, MedicationKnowledge, Reference } from "fhir"
import * as Yup from "yup"

const getInventoryInitialValues = (organization: Reference, location: Reference) =>
  ({
    status: "active",
    code: undefined,
    netContent: { value: undefined },
    resourceType: "InventoryItem",
    instance: {
      subject: organization,
      location,
      // lotNumber: "",
      // expiry: undefined,
      // identifier: [{ system: "http://chartedhealth.com/fhir/product-inventory-number", value: undefined }],
    },
    med: undefined,
  }) as InventoryItem & { med?: MedicationKnowledge & { textDisplayedInField?: string } }

const inventoryItemValidationSchema = Yup.object().shape({
  netContent: Yup.object().shape({ value: Yup.number().nullable().required("Quantity is required") }),
  // instance: Yup.object().shape({
  //   lotNumber: Yup.string().required("Lot number is required"),
  //   expiry: Yup.string().required("Expiration date is required"),
  //   identifier: Yup.array(Yup.object().shape({ value: Yup.string().required("Inventory number is required") })),
  // }),
})

export { getInventoryInitialValues, inventoryItemValidationSchema }
