import { useQuery } from "@tanstack/react-query"
import {
  Appointment,
  isAppointment,
  isLocation,
  isPatient,
  isPractitioner,
  Location,
  Patient,
  Practitioner,
} from "fhir"

import { useClient } from "api"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { practitionerAptsQueryKeys } from "../query-keys"
import { CalendarAppointment } from "../types"

const usePractitionerAppointments = ({
  currentOrganizationId,
  patientId,
  practitionerId,
  appointmentType,
  start,
  end,
}: {
  currentOrganizationId: string
  patientId?: string
  practitionerId?: string
  start?: Date
  end?: Date
  appointmentType?: string
}) => {
  const { search } = useClient()
  const queryKey = practitionerAptsQueryKeys.all(
    currentOrganizationId,
    start,
    end,
    patientId,
    practitionerId,
    appointmentType,
  )

  const {
    isSuccess,
    data: appointments,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "booked",
        _include: "actor",
        ...(patientId ? { patient: patientId } : {}),
        ...(practitionerId ? { practitioner: practitionerId } : {}),
        ...(appointmentType ? { actor: `HealthcareService/${appointmentType}` } : {}),
        _sort: "-date",
        "actor:Patient.organization": currentOrganizationId,
      })

      start && filters.append("date", `ge${formatDate(start, formatsByTypes.ISO_8601_DATE)}`)
      end && filters.append("date", `le${formatDate(end, formatsByTypes.ISO_8601_DATE)}`)

      const { entry } = await search({ endpoint: "Appointment", filters, signal })

      const { appointments, practitioners, patients, locations } =
        entry?.reduce<{
          appointments: Appointment[]
          practitioners: Record<string, Practitioner>
          patients: Record<string, Patient>
          locations: Record<string, Location>
        }>(
          (prev, { resource }) => {
            if (isAppointment(resource) && resource.id) {
              return {
                ...prev,
                appointments: [...prev.appointments, resource],
              }
            }

            if (isPractitioner(resource) && resource.id) {
              return { ...prev, practitioners: { ...prev.practitioners, [resource.id]: resource } }
            }

            if (isPatient(resource) && resource.id) {
              return { ...prev, patients: { ...prev.patients, [resource.id]: resource } }
            }

            if (isLocation(resource) && resource.id) {
              return { ...prev, locations: { ...prev.locations, [resource.id]: resource } }
            }

            return prev
          },
          { appointments: [], practitioners: {}, patients: {}, locations: {} },
        ) ?? {}

      return (
        appointments?.map<CalendarAppointment>((appointment) => {
          const { practitioner, patient, location } = appointment.participant.reduce<{
            practitioner?: Practitioner
            patient?: Patient
            location?: Location
          }>((prev, { actor }) => {
            if (isPractitioner(actor) && actor.id) {
              return { ...prev, practitioner: practitioners?.[actor.id] }
            }

            if (isPatient(actor) && actor.id) {
              return { ...prev, patient: patients?.[actor.id] }
            }

            if (isLocation(actor) && actor.id) {
              return { ...prev, location: locations?.[actor.id] }
            }

            return prev
          }, {})

          return {
            appointment,
            practitioner,
            patient,
            location,
          }
        }) ?? []
      )
    },
    meta: { context: { queryKey, patientId } },
  })

  if (isError) {
    throw error
  }

  return { appointments, isLoading, isSuccess }
}

export { usePractitionerAppointments }
