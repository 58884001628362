import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isPractitioner } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC, useEffect, useMemo } from "react"

import { AutoCompletePatientField, ReferenceDropdownField } from "commons"
import { useOrganizationContext } from "organization"

import { telemedicineServiceCC } from "../data"
import { useScheduleAppointmentContext } from "../hooks"
import { AppointmentFormData } from "../types"
import { AppointmentCalendar } from "./AppointmentCalendar"

const AppointmentCalendarSection: FC<Props> = ({ hidePatientField, isEditing }) => {
  const { updateSelectedDate } = useScheduleAppointmentContext()
  const { healthcareServicesRefs, healthcareServices } = useOrganizationContext()
  const {
    values: { participant },
    setFieldValue,
  } = useFormikContext<AppointmentFormData>()
  const selectedHealthcareService = useMemo(
    () => healthcareServices.find(({ id }) => id === participant[5]?.actor?.id),
    [healthcareServices, participant],
  )
  const practitionersRefs = useMemo(
    () => selectedHealthcareService?.participant?.filter(isPractitioner) ?? [],
    [selectedHealthcareService],
  )
  const duration = selectedHealthcareService?.duration

  useEffect(() => {
    if (duration?.value) setFieldValue("minutesDuration", duration?.value)
  }, [duration])

  useEffect(() => {
    updateSelectedDate(undefined)
  }, [participant[1].actor])

  useEffect(() => {
    const isTelemedicineAppointment = selectedHealthcareService?.characteristic?.some(
      ({ coding }) => coding?.[0]?.code === "telemedicine",
    )
    setFieldValue("virtualService", isTelemedicineAppointment ? [telemedicineServiceCC] : undefined)
  }, [selectedHealthcareService])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <ReferenceDropdownField
          field="participant[5].actor"
          label="Appointment Type"
          optionLabel="id"
          options={healthcareServicesRefs}
          className="w-full"
          isClearable
          showFilter={false}
        />
        <div
          className={classNames("flex items-center -mt-3 text-gray-400 gap-1 text-sm", {
            invisible: !duration,
            visible: !!duration,
          })}
        >
          <FontAwesomeIcon icon={faClock} />
          {duration?.value} {duration?.unit} session
        </div>
      </div>
      {!hidePatientField && (
        <AutoCompletePatientField
          field="participant[0].actor"
          label="Patient"
          isReadonly={isEditing}
          className="mb-2"
          withPatientDetails
        />
      )}
      <ReferenceDropdownField
        field="participant[1].actor"
        label="Practitioner"
        options={practitionersRefs}
        className="w-full"
        isClearable
        disabled={!selectedHealthcareService}
      />
      <AppointmentCalendar />
    </div>
  )
}

type Props = {
  hidePatientField?: boolean
  isEditing?: boolean
}

export { AppointmentCalendarSection }
