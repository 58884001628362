import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { format, isValid, parse, roundToNearestMinutes } from "date-fns"
import { FieldProps, useFormikContext } from "formik"
import { Calendar } from "primereact/calendar"
import { FormEvent } from "primereact/ts-helpers"
import { classNames } from "primereact/utils"
import { FC, useEffect, useRef } from "react"

import { Button } from "../components/Buttons"
import { FormatTypes } from "../types"
import { FormField, FormFieldBaseProps } from "./FormField"

const DateField: FC<Props> = ({
  field,
  label,
  className,
  dateFormat,
  disabled,
  placeholder,
  showTime,
  timeOnly,
  view = "date",
  minDate,
  maxDate,
  selectionMode = "single",
  readOnlyInput,
  horizontal,
  inputClassName,
  onChange,
  validation,
  ...formFieldProps
}) => {
  const cal = useRef<Calendar>(null)
  const { getFieldMeta, setFieldValue } = useFormikContext()
  const initVal = getFieldMeta(field).initialValue as string | Date | Date[]

  const getParsedValue = (value: Date | Date[] | null | undefined, timeOnly: boolean = false) => {
    if (Array.isArray(value))
      return timeOnly
        ? [format(value[0], "HH:mm:ss"), format(value[1], "HH:mm:ss")]
        : [value[0]?.toISOString(), value[1]?.toISOString()]
    else return value ? (timeOnly ? format(value, "HH:mm:ss") : value.toISOString()) : null
  }

  const roundDate = (val: Date | string, timeOnly: boolean = false) =>
    roundToNearestMinutes(
      timeOnly
        ? parse(
            isValid(new Date(val))
              ? (val as string)
              : /\d{1,2}:\d{1,2}:\d{1,2}/.test(val as string)
                ? (val as string)
                : "00:00:00",
            "HH:mm:ss",
            new Date(),
          )
        : isValid(new Date(val))
          ? new Date(val)
          : new Date(),
      {
        nearestTo: 15,
      },
    )

  useEffect(() => {
    if (initVal) {
      const initDate = Array.isArray(initVal)
        ? [roundDate(initVal[0], timeOnly), roundDate(initVal[1], timeOnly)]
        : roundDate(initVal, timeOnly)

      const parsedValue = getParsedValue(initDate, timeOnly)

      initVal !== parsedValue && !Array.isArray(parsedValue) && setFieldValue(field, parsedValue)
    } else cal.current?.updateViewDate(null, roundToNearestMinutes(new Date(), { nearestTo: 15 }))
  }, [])

  const handleChangeCalendar = (e: FormEvent<Date> | FormEvent<Date[]> | FormEvent<(Date | null)[]>) => {
    const { name, value } = e.target

    if (Array.isArray(value)) {
      setFieldValue(name, value)
    } else {
      setFieldValue(name, getParsedValue(value, timeOnly))
    }

    onChange?.(value ?? undefined)
  }

  return (
    <FormField
      field={field}
      validation={validation}
      label={label}
      className={className}
      horizontal={horizontal}
      {...formFieldProps}
    >
      {({ field: { name, value }, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => {
        const footer = () => (
          <div className="flex justify-end p-2 border-t pt-4">
            <Button
              label="Accept"
              icon={faCheck}
              buttonStyle="default"
              onClick={async () => {
                const calDate = cal.current?.getCurrentDateTime()
                if (calDate) {
                  if (Array.isArray(calDate)) {
                    await setFieldValue(name, calDate)
                  } else {
                    await setFieldValue(name, getParsedValue(calDate, timeOnly))
                  }
                  onChange?.(calDate)
                }
                cal.current?.hide()
              }}
            />
          </div>
        )

        return (
          <Calendar
            ref={cal}
            id={name}
            name={name}
            value={
              typeof value === "string"
                ? timeOnly
                  ? parse(value.split("T")[1] ?? value, value.includes("Z") ? "HH:mm:ss.SSSX" : "HH:mm:ss", new Date())
                  : new Date(value)
                : value
            }
            onChange={handleChangeCalendar}
            minDate={minDate}
            maxDate={maxDate}
            showIcon
            showTime={showTime}
            timeOnly={timeOnly}
            stepMinute={15}
            dateFormat={dateFormat}
            disabled={disabled}
            selectionMode={selectionMode}
            hideOnRangeSelection
            view={view}
            placeholder={placeholder}
            readOnlyInput={readOnlyInput}
            className={classNames(
              "p-inputtext-sm",
              {
                "p-invalid": touched && error,
                horizontal: horizontal,
              },
              inputClassName,
            )}
            footerTemplate={showTime || timeOnly ? footer : undefined}
          />
        )
      }}
    </FormField>
  )
}

type Props = {
  field: string
  stringFormatType?: FormatTypes
  label?: string
  className?: string
  dateFormat?: string
  placeholder?: string
  showTime?: boolean
  view?: "date" | "month" | "year" | undefined
  timeOnly?: boolean
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  selectionMode?: "single" | "multiple" | "range"
  readOnlyInput?: boolean
  inputClassName?: string
  validation?(value: string): void
  onChange?(value?: Date | Date[] | (Date | null)[]): void
} & Omit<FormFieldBaseProps, "validation">

export { DateField }
