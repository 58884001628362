import { createContext, useContext } from "react"

import { MonthBounds } from "../types"

const ScheduleAppointmentContext = createContext<State | undefined>(undefined)

ScheduleAppointmentContext.displayName = "ScheduleAppointmentContext"

const useScheduleAppointmentContext = () => {
  const context = useContext(ScheduleAppointmentContext)

  if (!context) throw new Error("useScheduleAppointmentContext must be used within a ScheduleAppointmentProvider")

  return context
}

export type State = {
  selectedMonthBounds: MonthBounds
  updateSelectedMonthBounds(_: MonthBounds): void
  selectedDate?: Date
  updateSelectedDate(_?: Date): void
}

export { ScheduleAppointmentContext, useScheduleAppointmentContext }
