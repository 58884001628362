import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, BundleEntryArray, List, Parameters, ServiceRequest, getResources, isCarePlan } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { LaboratoryOrder, LaboratoryOrderCombo, LaboratoryOrderPanel } from "commons/labs"
import { isLabOrder } from "data"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { displayNotificationSuccess } from "utils"

import { labOrdersQueryKeys } from "../query-keys"

const useRevokeLabOrder = (closeForm: () => void, onSuccess?: () => void) => {
  const { transaction, operationRequest } = useClient()
  const queryClient = useQueryClient()

  const revoke = (order: ServiceRequest, revokeReason: string) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: [
        {
          item: {
            resourceType: "ServiceRequest",
            id: order.id,
          },
        },
      ],
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "orders",
          resource: list,
        },
        {
          name: "reason",
          value: {
            string: revokeReason,
          },
        },
      ],
    }

    return operationRequest({
      endpoint: `Patient/${order.subject.id}/cpoe`,
      method: "POST",
      operation: "cancel",
      parameters,
    })
  }

  const remove = (order: ServiceRequest, panels: LaboratoryOrderPanel[], combo?: LaboratoryOrderCombo) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [],
    }

    const allPanels = [...panels, ...(combo?.panels ?? [])]

    // QuestionnaireResponses
    const qrEntries = allPanels.reduce<BundleEntryArray[]>((acc, panel) => {
      const entries = panel?.questionnairesResponses?.map((qr) => {
        return {
          request: {
            method: "DELETE",
            url: `QuestionnaireResponse/${qr?.id}`,
          },
        }
      })

      return [...acc, ...(entries ?? [])]
    }, [])

    bundle.entry = [...(bundle.entry ?? []), ...qrEntries]

    // Tests
    const panelsEntries = allPanels.map((resource) => {
      const {
        profile: { id },
      } = resource

      return {
        request: {
          method: "DELETE",
          url: `ServiceRequest/${id}`,
        },
      }
    }) as BundleEntryArray[]

    bundle.entry = [...(bundle.entry ?? []), ...panelsEntries]

    // Combo
    if (combo) {
      const comboEntry = {
        request: {
          method: "DELETE",
          url: `ServiceRequest/${combo?.laboratoryCombo.id}`,
        },
      }
      bundle.entry = [...(bundle.entry ?? []), comboEntry]
    }

    // Specimen
    if (order.specimen) {
      const specimenEntry = {
        request: {
          method: "DELETE",
          url: `Specimen/${order.specimen?.[0].id}`,
        },
      }
      bundle.entry = [...(bundle.entry ?? []), specimenEntry]
    }

    // Sr root
    const rootEntry = {
      request: {
        method: "DELETE",
        url: `ServiceRequest/${order.id}`,
      },
    }

    bundle.entry = [...(bundle.entry ?? []), rootEntry]

    return transaction(bundle)
  }

  const revokeFunction = async ({
    labOrder: { order, panels, combo },
    revokeReason,
  }: {
    labOrder: LaboratoryOrder
    revokeReason: string
  }) => {
    if ((order.status === "draft" || order.status === "on-hold") && !order.basedOn?.some(isCarePlan)) {
      return remove(order, panels, combo)
    }

    return revoke(order, revokeReason)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: revokeFunction,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (bundle, { labOrder }) => {
      const srs = getResources<ServiceRequest>(bundle as Bundle, "ServiceRequest")
      const srRoot = srs.find((sr) => isLabOrder(sr))
      const subjectId = srRoot?.subject.id ?? labOrder.order.subject.id ?? ""

      queryClient.refetchQueries({
        queryKey: labOrdersQueryKeys.withPatientID(subjectId),
        type: srRoot?.subject.id ? "all" : undefined,
      })
      queryClient.refetchQueries({
        queryKey: srRoot?.subject.id ? ordersQueryKeys.count.withPatientId(subjectId) : ordersQueryKeys.count.all,
        type: srRoot?.subject.id ? "all" : undefined,
      })
      await queryClient.invalidateQueries({
        queryKey: ordersQueryKeys.list(subjectId),
        refetchType: srRoot?.subject.id ? "all" : undefined,
      })

      displayNotificationSuccess(
        `Lab order has been ${
          srRoot?.status === "draft" || srRoot?.status === "on-hold" ? "discarded" : "cancelled"
        } successfully!`,
      )
      // datadogLogs.logger.info(
      //   `Lab order has been ${
      //     srRoot?.status === "draft" || srRoot?.status === "on-hold" ? "discarded" : "cancelled"
      //   } successfully!`,
      //   { labOrder },
      // )
      onSuccess?.()
    },
    onSettled: closeForm,
  })

  return { revokeLabOrder: mutate, isRevoking: isPending }
}

export { useRevokeLabOrder }
