import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Coding, Composition } from "fhir"
import { Button } from "primereact/button"
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react"

import { SearchInput } from "commons"

import { MEDICATIONS_SECTION_TYPE, MedicationsAdvanceFilter } from "../../types"

const MedicationsFilters = ({
  activeTab,
  filters,
  catalogOptions,
  medicineClassificationOptions,
  onFilter,
  onSearch,
  onReset,
}: Props) => {
  const { mkCatalogs, searchText, medsClassificationCodes } = filters

  const overlayFilter = useRef<OverlayPanel>(null)
  const hasFilter = Object.entries(filters).some(([, value]) => (Array.isArray(value) ? !!value.length : !!value))

  return (
    <div className="flex w-full gap-2 h-12">
      <Button
        icon={
          <FontAwesomeIcon icon={faFilter} className={classNames(!hasFilter ? "text-slate-400" : "text-primary")} />
        }
        className="p-button-sm button-default"
        onClick={(e) => overlayFilter?.current?.toggle(e)}
      />
      <OverlayPanel
        ref={overlayFilter}
        showCloseIcon={false}
        dismissable
        id="overlay_panel"
        className="form-panel pb-3"
        breakpoints={{ "1330px": "25vw", "1024px": "45vw", "960px": "75vw", "640px": "90vw" }}
        style={{ width: "15vw" }}
      >
        <AdvancedMedsFilters
          initialValues={{ mkCatalogs: mkCatalogs, medsClassificationCodes: medsClassificationCodes }}
          mkCatalogs={catalogOptions}
          medicineClassificationCodings={medicineClassificationOptions}
          onClearFilters={() => {
            onReset()
            overlayFilter?.current?.hide()
          }}
          onChangeFilters={(field, value: boolean | string[]) =>
            field === "mkCatalogs"
              ? onFilter({ mkCatalogs: value as string[] })
              : onFilter({ medsClassificationCodes: value as string[] })
          }
          disableClearAll={!hasFilter}
        />
      </OverlayPanel>
      <SearchInput
        search={onSearch}
        autoFocus={false}
        placeholder={
          activeTab === MEDICATIONS_SECTION_TYPE.MEDICATIONS_RX_SECTION ? "Search Rx" : "Search Nutraceutical"
        }
        isLoading={false}
        className="flex w-full"
        initialValue={searchText}
      />
    </div>
  )
}

const AdvancedMedsFilters = ({
  initialValues,
  mkCatalogs,
  medicineClassificationCodings,
  disableClearAll,
  onChangeFilters,
  onClearFilters,
}: AdvancedFilterProps) => {
  const [selectedMkCatalogs, setSelectedMkCatalogs] = useState(initialValues?.mkCatalogs ?? [])
  const [selectedMedsClassificationCodes, setSelectedMedsClassificationCodes] = useState(
    initialValues?.medsClassificationCodes ?? [],
  )

  useEffect(() => {
    if (!selectedMedsClassificationCodes?.length) {
      setSelectedMedsClassificationCodes(initialValues.medsClassificationCodes!)
    }
  }, [initialValues.medsClassificationCodes])

  const onSelectedMkCatalogsChange = (e: CheckboxChangeEvent) => {
    const currentSelectedCatalogs = [...selectedMkCatalogs]
    if (e.checked) currentSelectedCatalogs.push(e.value)
    else currentSelectedCatalogs.splice(currentSelectedCatalogs.indexOf(e.value), 1)

    setSelectedMkCatalogs(currentSelectedCatalogs)
    onChangeFilters("mkCatalogs", currentSelectedCatalogs)
  }

  const onSelectedMedsClassificationCodesChange = (e: CheckboxChangeEvent) => {
    const currentSelectedMedsClassificationCodes = [...selectedMedsClassificationCodes]
    if (e.checked) currentSelectedMedsClassificationCodes.push(e.value)
    else currentSelectedMedsClassificationCodes.splice(currentSelectedMedsClassificationCodes.indexOf(e.value), 1)

    setSelectedMedsClassificationCodes(currentSelectedMedsClassificationCodes)
    onChangeFilters("medsClassificationCodes", currentSelectedMedsClassificationCodes)
  }

  return (
    <div className="flex flex-col h-full bg-white gap-2 ml-3 mr-5 divide-y divide-text-gray-400">
      <div className="flex w-full justify-end  bg-white">
        <Button
          type="button"
          label="Clear all"
          disabled={disableClearAll}
          onClick={onClearFilters}
          className="p-button-sm button-default p-button-text border-none bg-transparent text-gray-400"
        />
      </div>
      <div className="pt-6 pb-6 flex flex-col gap-3">
        <label className="text-sm text-gray-400">Medication Catalogs</label>
        {initialValues.mkCatalogs &&
          mkCatalogs?.map((item) => (
            <div key={item.id} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedMkCatalogsChange(e)
                }}
                checked={selectedMkCatalogs?.includes(item.id!)}
                value={item.id}
              />
              <label className="p-checkbox-label">{item.title}</label>
            </div>
          ))}
      </div>
      {initialValues.medsClassificationCodes && (
        <div className="pt-6 pb-6 flex flex-col gap-3">
          <label className="text-sm text-gray-400">Medication Classifications</label>
          {medicineClassificationCodings?.map((item) => (
            <div key={item.code} className="flex flex-row gap-4">
              <Checkbox
                onChange={(e) => {
                  onSelectedMedsClassificationCodesChange(e)
                }}
                checked={selectedMedsClassificationCodes?.includes(item.code!)}
                value={item.code}
              />
              <label className="p-checkbox-label">{item.display}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type AdvancedFilterProps = {
  initialValues: MedicationsAdvanceFilter & { searchText?: string }
  mkCatalogs: Composition[]
  medicineClassificationCodings?: Coding[]
  onChangeFilters(field: string, value: boolean | string[]): void
  onClearFilters(): void
  disableClearAll: boolean
}

type Props = {
  filters: MedicationsAdvanceFilter & { searchText?: string }
  activeTab?: MEDICATIONS_SECTION_TYPE
  catalogOptions: Composition[]
  medicineClassificationOptions?: Coding[]
  onFilter: (filters: MedicationsAdvanceFilter) => void
  onSearch: (searchText: string) => void
  onReset: () => void
}

export { MedicationsFilters }
