import {
  faCalendarCheck,
  faClipboardListCheck,
  faMemoCircleInfo,
  faPrescriptionBottlePill,
  faUserDoctor,
  faVials,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Patient, Reference } from "fhir"
import { Form, Formik, FormikHelpers } from "formik"
import { Chip } from "primereact/chip"
import { FC, ReactNode, useEffect, useMemo, useState } from "react"

import { MC_ACTIVITY_TYPE } from "data"

import { Button } from "../../../components/Buttons"
import { ConfirmDialog } from "../../../components/ConfirmDialog"
import { SplitButton } from "../../../components/SplitButton"
import { PractitionerInfo } from "../../../types"
import { useRequestedConfigs } from "../../hooks"
import { PLAN_ACTION_CODES, PlanData } from "../../types"
import { planValidationSchema } from "../validations"
import { LabsFormSection } from "./LabsFormSection"
import { OrderAndTaskInfo } from "./OrderAndTaskInfo"
import "./PlanSectionedForm.css"
import { Appointment } from "./sections/Appointment"
import { Assessment } from "./sections/Assessment"
import { Medications } from "./sections/Medications"

const PlanSectionedForm: FC<Props> = ({
  contextLabel,
  initialValues,
  openEncounter,
  practitionersInfo,
  isProcessingAction,
  isCanceling,
  patient,
  onSubmit,
  onClose,
  handleActivate,
  handleCancel,
}) => {
  const [cancelConfirm, setCancelConfirm] = useState(false)
  const { requiresAlgorithm, requiresEmails, requiresLabs, requiresNutras, requiresRXs } = useRequestedConfigs(
    initialValues.configureActions,
  )
  const hasConfigurableMailTask = Object.values(initialValues.mailTasks ?? {}).some(
    ({ editable, showable }) => !!editable && !!showable,
  )

  const optionalSections = useMemo(
    () =>
      Object.entries(initialValues.configureActions ?? []).reduce((acc, [key, action]) => {
        if (action.requiredBehavior === "must") return acc
        return [...acc, key]
      }, Array<string>()),
    [initialValues],
  )

  const renderSectionHeader = (title: string, actions?: string[]) => (
    <header className="inline-flex items-start text-base font-medium leading-5">
      {title}
      {actions !== undefined && actions.every((action) => optionalSections.includes(action)) && (
        <span className="text-[0.6rem] text-gray-500 ml-[0.125rem]">(optional)</span>
      )}
    </header>
  )

  const { sections }: { sections: PlanFormSection[] } = useMemo(() => {
    const configs = { requiresAlgorithm, requiresEmails, requiresLabs, requiresNutras, requiresRXs }

    const sections: PlanFormSection[] = initialValues.appointment?.id
      ? [
          {
            id: "appointment",
            title: "Appointment",
            header: renderSectionHeader("Appointment"),
            icon: faCalendarCheck,
            children: (
              <Appointment practitionersInfo={practitionersInfo} configs={configs} contentClassName="col-span-3" />
            ),
          },
        ]
      : !requiresAlgorithm
        ? [
            {
              id: "requester",
              title: "Requester",
              header: renderSectionHeader("Requester"),
              icon: faUserDoctor,
              children: (
                <Appointment practitionersInfo={practitionersInfo} configs={configs} contentClassName="col-span-3" />
              ),
            },
          ]
        : []

    if (requiresAlgorithm) {
      sections.push({
        id: PLAN_ACTION_CODES.CONFIGURE_ALGORITHM,
        title: "Assessment",
        header: renderSectionHeader("Assessment", [PLAN_ACTION_CODES.CONFIGURE_ALGORITHM]),
        icon: faClipboardListCheck,
        children: <Assessment practitionersInfo={practitionersInfo} configs={configs} contentClassName="col-span-3" />,
      })
    }

    if (requiresEmails && !requiresAlgorithm && hasConfigurableMailTask) {
      sections.push({
        id: `${PLAN_ACTION_CODES.CONFIGURE_EMAILS}-${PLAN_ACTION_CODES.CONFIGURE_QUESTIONNAIRES}`,
        title: "Questionnaires & Consent",
        header: renderSectionHeader("Questionnaires & Consent"),
        icon: faMemoCircleInfo,
        children: <OrderAndTaskInfo requiresEmails contentClassName="col-span-3" />,
      })
    }

    if (requiresLabs) {
      sections.push({
        id: PLAN_ACTION_CODES.CONFIGURE_LABS,
        title: "Labs",
        header: renderSectionHeader("Labs", [PLAN_ACTION_CODES.CONFIGURE_LABS]),
        icon: faVials,
        children: (
          <LabsFormSection
            configureActions={initialValues.configureActions}
            practitionersInfo={practitionersInfo}
            patient={patient}
          />
        ),
      })
    }

    if (requiresNutras || requiresRXs) {
      sections.push({
        id: `${PLAN_ACTION_CODES.CONFIGURE_NUTRAS}-${PLAN_ACTION_CODES.CONFIGURE_RXS}`,
        title: "Medications",
        header: renderSectionHeader("Medications", [
          PLAN_ACTION_CODES.CONFIGURE_NUTRAS,
          PLAN_ACTION_CODES.CONFIGURE_RXS,
        ]),
        icon: faPrescriptionBottlePill,
        children: (
          <Medications
            requiresNutras={requiresNutras}
            requiresRXs={requiresRXs}
            practitionersInfo={practitionersInfo}
            openEncounter={openEncounter}
            configureActions={initialValues.configureActions}
            stepId={`${PLAN_ACTION_CODES.CONFIGURE_NUTRAS}-${PLAN_ACTION_CODES.CONFIGURE_RXS}`}
          />
        ),
      })
    }

    return { sections }
  }, [initialValues])

  const handleActivateChip = (sectionId: string) => {
    const chips = document.querySelectorAll(".header-chip")
    chips.forEach((chip) => chip.classList.remove("chip-active"))
    const relatedChip = document.getElementById(`${sectionId}-header-chip`)
    relatedChip?.classList.add("chip-active")
  }

  useEffect(() => {
    const handleChipIntersectionAnimation = () => {
      const sections = document.querySelectorAll(".form-section")

      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        let closestEntry: IntersectionObserverEntry | null = null

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!closestEntry || entry.boundingClientRect.top < closestEntry.boundingClientRect.top) {
              closestEntry = entry
            }
          }
        })

        if (closestEntry) {
          const sectionId = (closestEntry as IntersectionObserverEntry).target.id
          handleActivateChip(sectionId)
        }
      }

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }

      const observer = new IntersectionObserver(handleIntersection, options)

      sections.forEach((section) => observer.observe(section))

      return () => {
        observer.disconnect()
      }
    }

    handleChipIntersectionAnimation()
  }, [])

  const handleGoToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId)
    if (section) {
      handleActivateChip(sectionId)
      section.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={planValidationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, validateForm, values: { mcActivity } }) => {
        const formSections =
          mcActivity?.detail?.kind === MC_ACTIVITY_TYPE.MC_SURVEY
            ? sections.filter(({ id }) => id !== PLAN_ACTION_CODES.CONFIGURE_LABS)
            : sections

        return (
          <Form aria-autocomplete="none" autoComplete="off" className="@container flex flex-col h-full">
            <header className="flex flex-col gap-[1.375rem] sticky z-10 top-0 pl-[1.4375rem] pr-[0.6875rem]">
              <h1 className="text-lg leading-[1.3125rem] text-gray-900 font-medium">Configure plan</h1>
              <section className="flex gap-y-[0.625rem] gap-x-4">
                {formSections.map(({ title, id, icon }) => (
                  <Chip
                    key={id}
                    id={`${id}-header-chip`}
                    label={title}
                    icon={<FontAwesomeIcon className="fa-fw" icon={icon} />}
                    className="header-chip flex h-[2.3125rem] gap-x-2 px-4 py-[0.625rem] hover:cursor-pointer text-sm leading-[1.0625rem]"
                    onClick={() => handleGoToSection(id)}
                  />
                ))}
              </section>
            </header>
            <main className="stepper-form flex flex-col flex-1 h-full overflow-y-auto overflow-x-hidden mt-[1.21875rem] mr-8 mb-11 gap-y-[1.0625rem] pl-[1.4375rem] pr-[0.6875rem]">
              {formSections.map(({ id, header, children }) => (
                <section
                  key={id}
                  id={id}
                  className="form-section flex flex-col last:border-none border-b border-gray-200 pb-[1.875rem] gap-y-6"
                >
                  {header}
                  <main className="p-8 pb-4 border border-solid border-gray-200 rounded-lg grid grid-cols-6 gap-x-6 gap-y-4">
                    {children}
                  </main>
                </section>
              ))}
            </main>
            <footer className="flex flex-shrink-0 justify-end gap-3 p-4 border-gray-200 border-t-2">
              {handleCancel && (
                <Button
                  label="Cancel"
                  buttonStyle="default"
                  size="lg"
                  title={`Cancel ${contextLabel}`}
                  disabled={isProcessingAction}
                  onClick={() => setCancelConfirm(true)}
                />
              )}
              <Button label="Close" buttonStyle="default" size="lg" disabled={isSubmitting} onClick={onClose} />
              <SplitButton
                size="lg"
                type="submit"
                loading={isProcessingAction}
                autoUpdateMainAction
                actions={[
                  {
                    label: "Save & Activate",
                    description: `Save current configuration and activate ${contextLabel}`,
                    onSelectClick: async () => {
                      const errors = await validateForm()
                      if (!Object.keys(errors).length) return handleActivate(initialValues.carePlan.id)
                    },
                  },
                  {
                    label: "Save",
                    description: "Save current configuration",
                  },
                ]}
              />
            </footer>

            {handleCancel && (
              <ConfirmDialog
                visible={cancelConfirm}
                confirmText={`Are you sure you want to cancel this ${contextLabel}?`}
                isLoading={isCanceling}
                waitToFinish
                onConfirm={handleCancel}
                hideDialog={() => setCancelConfirm(false)}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

type PlanFormSection = {
  id: string
  title: string
  header: ReactNode
  icon: IconDefinition
  children: ReactNode
}

type Props = {
  patient: Patient
  contextLabel: string
  initialValues: PlanData
  practitionersInfo: PractitionerInfo[]
  openEncounter?: Reference
  isProcessingAction?: boolean
  isCanceling?: boolean
  handleActivate(planId?: string): void
  handleCancel?(): void
  onSubmit(data: PlanData, formikHelpers?: FormikHelpers<PlanData>): void
  onClose(): void
}

export { PlanSectionedForm }
