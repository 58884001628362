import { useMutation, useQueryClient } from "@tanstack/react-query"
import { InventoryItem, MedicationKnowledge, Parameters, ResourceObject } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useOrganizationContext } from "organization"
import { displayNotificationSuccess } from "utils"

import { inventoryQueryKeys } from "./query-keys"

const useCreateInventoryItem = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { location } = useOrganizationContext()

  const createItem = async ({ med, ...item }: InventoryItem & { med?: MedicationKnowledge }) => {
    if (!med) {
      throw Error("Medication must be specified")
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "inventoryItem",
          resource: { ...item, code: [med.code] } as ResourceObject,
        },
      ],
    }

    return operationRequest({
      endpoint: "",
      method: "POST",
      operation: item.id ? "update-inventory-item" : "create-inventory-item",
      parameters,
    })
  }

  const { mutate: createInventoryItem, isPending } = useMutation({
    mutationFn: createItem,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: inventoryQueryKeys.all(location?.id as string),
      })

      displayNotificationSuccess("Inventory updated successfully!")
    },
    onSettled,
  })

  return { createInventoryItem, isPending }
}

export { useCreateInventoryItem }
