import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Invoice, ServiceRequest } from "fhir"
import { Button } from "primereact/button"
import { MenuItem } from "primereact/menuitem"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { useChartContext } from "chart-view"
import { srCategoryCodes } from "data"
import { SYSTEM_VALUES } from "system-values"
import { getInvoicesInfo, getMoneyCurrencyAlt, getOrderType } from "utils"

import { DropdownMenu } from "../../components/DropdownMenu"
import { ModulesId } from "../../Module"
import { getOrderDate } from "../transformers"

const MedicationOrderDetailHeader: FC<Props> = ({
  serviceRequest,
  invoices,
  activeActions,
  menuIsLoading,
  className,
  hiddenActions,
  hideNavigateAction,
  hideEditedOrderDetails,
  headerText,
}) => {
  const { showModule } = useChartContext()
  const navigate = useNavigate()

  const medOrderStatus = serviceRequest?.status
  const medOrderType = getOrderType(serviceRequest)

  const lifeFileId = serviceRequest?.identifier?.find(
    ({ system }) => system === SYSTEM_VALUES.LIFEFILE_MEDICATION_ORDER,
  )
  const medOrderIdentifier = serviceRequest?.identifier?.find(({ system }) => system === SYSTEM_VALUES.MEDICATION_ORDER)
  const showActions = !hiddenActions

  const goBack = () => {
    if (serviceRequest?.id)
      showModule({
        module:
          medOrderType === srCategoryCodes["nutraceutical-order"].code ? ModulesId.MEDICATIONR : ModulesId.EPRESCRIBE,
        moduleParams: {
          ...(medOrderStatus ? { subview: medOrderStatus === "active" ? "orders" : "history" } : {}),
        },
      })
    else navigate(-1)
  }

  const { totalPrice, identifier } = getInvoicesInfo(invoices)

  return (
    <div className={className}>
      <div className="flex justify-between items-center p-1">
        <div className="flex items-baseline gap-2">
          {!hideNavigateAction && (
            <span onClick={goBack} className="cursor-pointer text-gray-900 h-6 w-6 text-center">
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
          )}
          {headerText}
          <span title="Order number" className="text-primary font-medium text-lg">
            {medOrderIdentifier?.value ?? "Unspecified number"}
          </span>
        </div>
        {showActions && (
          <div className="inline-flex items-center gap-2">
            <Button
              className="p-button-sm p-button-text"
              label={activeActions[0].label}
              icon={activeActions[0].icon}
              onClick={activeActions[0].command}
              disabled={activeActions[0].disabled}
              loading={activeActions[0].loading}
            />
            {activeActions.length > 1 && (
              <Button
                className="p-button-sm p-button-text"
                label={activeActions[1].label}
                icon={activeActions[1].icon}
                onClick={activeActions[1].command}
                disabled={activeActions[1].disabled}
                loading={activeActions[1].loading}
              />
            )}

            {activeActions.length > 2 && (
              <DropdownMenu dropdownMenuItems={activeActions.slice(2)} loading={menuIsLoading} />
            )}
          </div>
        )}
      </div>
      <div className="flex flex-wrap gap-1 justify-between text-sm">
        <div className="flex gap-1">
          <label className="text-gray-900">Requester:</label>
          <span className="text-gray-400">{serviceRequest?.requester?.display ?? "unspecified"}</span>
        </div>
        <div className="flex gap-1">
          <label className="text-gray-900">Invoice:</label>
          <span className="text-gray-400">{`#${identifier.join(", #") ?? "unspecified number"}`}</span>
        </div>
        {!hideEditedOrderDetails && (
          <>
            <div className="flex gap-1">
              <label className="text-gray-900">Date:</label>
              <span className="text-gray-400">{getOrderDate(serviceRequest)}</span>
            </div>
            <div className="flex gap-1">
              <label className="text-gray-900">Status:</label>
              <span className="text-gray-400 capitalize">
                {(serviceRequest?.status !== "revoked" ? serviceRequest?.status : "cancelled") ?? "unspecified"}
              </span>
            </div>
          </>
        )}
        {!!lifeFileId && (
          <div className="flex gap-1">
            <label className="text-gray-900">LifeFile ID:</label>
            <span className="text-gray-400 capitalize">{lifeFileId?.value}</span>
          </div>
        )}
        {!!totalPrice && (
          <div className="flex gap-1">
            <label className="text-gray-900">Price:</label>
            <span className="text-gray-400">{`${totalPrice.value.isLessThan(0) ? "-" : ""}${getMoneyCurrencyAlt(totalPrice.currency)}${totalPrice.value.isLessThan(0) ? (totalPrice.value.toNumber() * -1).toFixed(2) : totalPrice.value.toFixed(2)}`}</span>
          </div>
        )}
      </div>
    </div>
  )
}

type Props = {
  serviceRequest?: ServiceRequest
  invoices?: Invoice[]
  menuIsLoading?: boolean
  className?: string
  hideNavigateAction?: boolean
  hideEditedOrderDetails?: boolean
  headerText?: string | JSX.Element
} & ConditionalActions

type ConditionalActions =
  | {
      hiddenActions: true
      activeActions?: (MenuItem & { command(): void; loading?: boolean })[]
    }
  | {
      hiddenActions?: false
      activeActions: (MenuItem & { command(): void; loading?: boolean })[]
    }

export { MedicationOrderDetailHeader }
