import { faChevronLeft, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form, Formik, FormikErrors, FormikProps, FormikValues } from "formik"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import { PropsWithChildren, ReactNode, useCallback, useMemo } from "react"

import { Button } from "../components/Buttons"
import { useReplaceFormContext } from "../hooks"
import { DialogFormProps } from "./types"

const DialogFormContainer = <T extends FormikValues>({
  showForm,
  title,
  className,
  onCancel,
  supportingInfo,
  ...props
}: PropsWithChildren<Props<T>>) => {
  const context = useReplaceFormContext<T>()

  const renderReplacementContent = useMemo(
    () => context?.replacementContent?.showForm ?? false,
    [context?.replacementContent?.showForm],
  )

  const onCloseReplacementContent = useCallback(() => {
    context?.toogleShowReplacementContent?.()
    context?.replacementContent?.onCancel()
  }, [context])

  const onClose = useCallback(
    () => (renderReplacementContent ? onCloseReplacementContent() : onCancel()),
    [renderReplacementContent, onCloseReplacementContent],
  )

  const content = ({
    formProps: {
      isLoadingData,
      customSaveButton,
      children,
      saveLabel,
      innerContainerClassName = "overflow-y-auto p-4 pt-0 sm:px-6 space-y-4 grow",
      footerClassName,
      onSubmit,
    },
    useSubmit,
    isSubmitting,
    validateForm,
  }: {
    formProps: PropsWithChildren<Partial<Props<T>>>
    useSubmit: boolean
    isSubmitting?: boolean
    validateForm?: (values?: T) => Promise<FormikErrors<T>>
  }) => (
    <>
      <div className="grid grid-flow-col-dense gap-2 divide-x overflow-hidden flex-grow">
        <section className={classNames(`${innerContainerClassName}`, !supportingInfo ? "px-6" : "flex-1 pr-2")}>
          {children}
        </section>
        {!!supportingInfo && (
          <section className="flex flex-col overflow-hidden h-full pl-2 pr-1">{supportingInfo}</section>
        )}
      </div>
      <div className={classNames("flex flex-shrink-0 justify-end px-4 py-4 sm:px-6 w-full relative", footerClassName)}>
        {isLoadingData && (
          <span className="absolute left-6 self-center">
            <FontAwesomeIcon icon={faSpinner} spin className="mr-1" />
            Loading data<p className="animate-pulse inline">...</p>
          </span>
        )}
        <Button
          label="Close"
          buttonStyle="default"
          size="xl"
          className="mr-3"
          disabled={isSubmitting || isLoadingData}
          onClick={onClose}
        />
        {customSaveButton ? (
          typeof customSaveButton === "function" ? (
            customSaveButton({
              validate: validateForm ?? (() => Promise.resolve({})),
              isSubmitting,
            })
          ) : (
            customSaveButton
          )
        ) : (
          <Button
            label={saveLabel}
            type={useSubmit ? "submit" : "button"}
            size="xl"
            loading={isSubmitting}
            onClick={!useSubmit ? () => onSubmit?.() : undefined}
            disabled={isLoadingData}
          />
        )}
      </div>
    </>
  )

  const renderContent = useCallback(
    (replacement: boolean = false, hide?: boolean) => {
      const {
        onSubmit,
        customSaveButton,
        initialValue,
        isLoadingData,
        saveLabel = "Save",
        validationSchema,
        children,
        useFormik = initialValue !== undefined && validationSchema !== undefined,
        enableReinitialize = true,
        innerContainerClassName,
        footerClassName,
        ...formikProps
      } = replacement ? context?.replacementContent ?? props : props

      return useFormik && initialValue ? (
        <Formik
          key={`formik-${replacement ? "replacement" : "default"}-instance`}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={enableReinitialize}
          {...formikProps}
        >
          {({ isSubmitting, validateForm }: FormikProps<T>) => (
            <Form
              className={classNames("bg-white divide-gray-200 divide-y flex flex-col flex-1 overflow-hidden", {
                hidden: hide,
              })}
              aria-autocomplete="none"
              autoComplete="off"
            >
              {content({
                formProps: {
                  children,
                  saveLabel,
                  isLoadingData,
                  customSaveButton,
                  innerContainerClassName,
                  footerClassName,
                  onSubmit,
                },
                useSubmit: true,
                isSubmitting,
                validateForm,
              })}
            </Form>
          )}
        </Formik>
      ) : (
        <div className="bg-white divide-gray-200 divide-y flex flex-col flex-1 overflow-hidden">
          {content({ formProps: { children, saveLabel, isLoadingData, customSaveButton, onSubmit }, useSubmit: false })}
        </div>
      )
    },
    [props, context?.replacementContent],
  )

  const dialogHeader = renderReplacementContent ? (
    <div className="inline-flex space-x-3 font-semibold items-center">
      <FontAwesomeIcon icon={faChevronLeft} className="cursor-pointer hover:text-primary-hover" onClick={onClose} />
      <h4 className="leading-4">{context?.replacementContent?.title ?? title}</h4>
    </div>
  ) : (
    title
  )

  return (
    <Dialog
      modal
      header={dialogHeader}
      visible={showForm}
      draggable={false}
      resizable={false}
      appendTo={props.useFormik && props.initialValue ? undefined : "self"}
      onHide={onCancel}
      className={classNames(
        "dialog-form-container w-full paddingless",
        supportingInfo ? "sm:w-4/5 lg:w-2/3 xl:w-3/5" : "sm:w-4/5 md:w-2/3 lg:w-3/5 xl:w-2/5",
        className,
      )}
    >
      {renderReplacementContent && renderContent(true)}
      {renderContent(false, renderReplacementContent)}
    </Dialog>
  )
}

type Props<T> = DialogFormProps<T> & Omit<Partial<FormikProps<T>>, "initialValues"> & { supportingInfo?: ReactNode }

export { DialogFormContainer }
