import { format, parseISO } from "date-fns"
import { Location, LocationHoursOfOperationArray } from "fhir"
import * as Yup from "yup"

import { dayOfWeek, formatsByTypes } from "data"

import { OperationHours, SpecialHoursData } from "./types"

export const getInitialValues = (location: Location) => {
  const initData = { regularHours: [], specialHours: [] }
  const { regularHours, specialHours } =
    location?.hoursOfOperation?.reduce<OperationHours>((acc, hop) => {
      if (hop.daysOfWeek && !hop.date) {
        const days = hop.daysOfWeek
          ?.map((day) => dayOfWeek.findIndex((dw) => dw.code === day))
          ?.sort()
          ?.join("|") as string
        const dayHOP = acc.regularHours.find((d) => d.days === days)
        if (dayHOP) dayHOP.hours.push(hop)
        else acc.regularHours.push({ days, dayCodes: hop.daysOfWeek ?? [], hours: [hop] })
      } else if (hop.date) {
        const date = format(parseISO(hop.date), formatsByTypes.ISO_8601_DATE)
        const dayHOP = acc.specialHours.find((d) => d.date === date)
        if (dayHOP) dayHOP.hours.push(hop)
        else
          acc.specialHours.push({
            date,
            hours: hop.openingTime && hop.closingTime ? [hop] : [],
            closed: hop.closed,
            description: hop.description,
            frequency: hop.frequency,
          })
      }
      return acc
    }, initData) ?? initData

  return { regularHours, specialHours }
}

export const getSpecialHourInitialValues = (): SpecialHoursData => ({
  date: format(new Date(), formatsByTypes.ISO_8601_DATE),
  hours: [
    {
      openingTime: "00:00:00",
      closingTime: "00:00:00",
    },
  ],
  closed: false,
  description: "",
  frequency: undefined,
})

export const specialHourValidationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
  date: Yup.date().required("Date is required"),
})

export const sanitize = (location: Location, data: OperationHours) => {
  const regularHours = data.regularHours.reduce<LocationHoursOfOperationArray[]>((acc, day) => {
    if (day.dayCodes.length) {
      const data = day.hours.map((hour) => ({
        daysOfWeek: day.dayCodes,
        openingTime: hour.openingTime,
        closingTime: hour.closingTime,
      }))
      return [...acc, ...data]
    }
    return acc
  }, [])
  const specialHours = data.specialHours.reduce<LocationHoursOfOperationArray[]>((acc, day) => {
    const defaultDateData = {
      date: format(parseISO(day.date), formatsByTypes.ISO_8601_DATE),
      closed: day.closed,
      description: day.description,
      frequency: day.frequency,
    }
    const days = day.hours.length
      ? day.hours.map((hour) => ({
          ...defaultDateData,
          openingTime: hour.openingTime,
          closingTime: hour.closingTime,
        }))
      : [defaultDateData]
    return [...acc, ...days]
  }, [])

  return { ...location, hoursOfOperation: [...regularHours, ...specialHours] }
}
