import { Appointment, Location, Practitioner, asReference } from "fhir"
import * as Yup from "yup"

import { AppointmentFormData } from "../types"
import { getSlotInitialValue } from "../utils"

const INITIAL_VALUES: Appointment = {
  status: "booked",
  start: undefined,
  end: undefined,
  description: "",
  participant: [],
  appointmentType: undefined,
  minutesDuration: 30,
  resourceType: "Appointment",
}

/*
 * participant[0]: patient
 * participant[1]: practitioner
 * participant[2]: location
 * participant[3]: room
 * participant[4]: device
 * participant[5]: healthcare service
 */

const initialValues = (
  appointment: Appointment = INITIAL_VALUES,
  practitioner: Practitioner,
  location?: Location,
): AppointmentFormData => {
  appointment.participant[0] = {
    actor: appointment.participant[0]?.actor,
    status: appointment.participant[0]?.status ?? "tentative",
  }
  appointment.participant[1] = {
    actor: appointment.participant[1]?.actor ?? asReference(practitioner),
    status: appointment.participant[1]?.status ?? "tentative",
  }
  appointment.participant[2] = {
    actor: appointment.participant[2]?.actor ?? (location ? asReference(location) : {}),
    status: appointment.participant[2]?.status ?? "tentative",
  }
  appointment.participant[3] = {
    actor: appointment.participant[3]?.actor,
    status: appointment.participant[3]?.status ?? "tentative",
  }
  appointment.participant[4] = {
    actor: appointment.participant[4]?.actor,
    status: appointment.participant[4]?.status ?? "tentative",
  }
  appointment.participant[5] = {
    actor: appointment.participant[5]?.actor,
    status: appointment.participant[5]?.status ?? "tentative",
  }

  return {
    ...appointment,
    selectedSlot: getSlotInitialValue(appointment.start, appointment.end),
  }
}

const appointmentValidationSchema = Yup.object().shape({
  minutesDuration: Yup.number().required("Must specify appointment duration").min(5, "Minimum duration of 5 minutes"),
  selectedSlot: Yup.object().required("Must select a time"),
  participant: Yup.array().of(
    Yup.object().shape({
      actor: Yup.object()
        .test("test-patient", "Patient is required", (value, context) => {
          return context.path === "participant[0].actor" ? value?.id : true
        })
        .test("test-practitioner", "Practitioner is required", (value, context) => {
          return context.path === "participant[1].actor" ? value?.id : true
        })
        .test("test-location", "Location is required", (value, context) => {
          return context.path === "participant[2].actor" ? value?.id : true
        }),
    }),
  ),
})

const sanitize = ({ ...appointment }: AppointmentFormData): Appointment => {
  if (appointment.comment === "") {
    delete appointment.comment
  }

  const sanitizedParticipants = appointment.participant.filter(({ actor }) => actor?.id)

  delete appointment.selectedSlot

  return { ...appointment, participant: sanitizedParticipants.length ? sanitizedParticipants : appointment.participant }
}

export { INITIAL_VALUES, appointmentValidationSchema, initialValues, sanitize }
