import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

import { Accordion } from "../../../components/Accordion"
import { StackedListItem } from "../../../components/StackedListItem"
import { PanelItemDisplay } from "../../types"
import { panelItemModelBuilder, panelModelBuilder } from "../../utils"

const ComboPanelItem: FC<Props> = ({ combo, showPrice, isInsurance, onDelete }) => {
  const renderComboPanels = (combo: PanelItemDisplay) => (
    <>
      {combo.panels?.map((panel, index) => (
        <StackedListItem
          key={panel.id ?? index}
          modelData={panelItemModelBuilder({
            pItem: { planDefinition: panel, display: panel.title ?? panel.name ?? "" },
            showPrice: false,
            isInsurance,
          })}
        />
      ))}
    </>
  )

  return (
    <Accordion
      data={[combo]}
      contentTemplate={renderComboPanels}
      headerTemplate={(item) => (
        <StackedListItem
          modelData={panelModelBuilder({ item, readOnly: false, onDelete, showPrice, isInsurance })}
          className={"py-4"}
          contentClassName="flex flex-1 justify-between @md:items-center"
        />
      )}
      headerClassName="paddingless"
      expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" className="mr-1" />}
      collapseIcon={<FontAwesomeIcon icon={faChevronUp} size="sm" className="mr-1" />}
      pt={{
        accordiontab: {
          headerAction: { className: "flex-row-reverse" },
        },
      }}
    />
  )
}

type Props = {
  combo: PanelItemDisplay
  showPrice?: boolean
  isInsurance: boolean
  onDelete?(comboSR: PanelItemDisplay): void
}

export { ComboPanelItem }
