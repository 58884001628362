import { isEqual } from "date-fns"
import { useFormikContext } from "formik"
import { FC, useEffect, useMemo } from "react"

import { ReferenceDropdownField, TextAreaField } from "commons"

import { useAppointmentParticipants } from "../hooks"
import { AppointmentFormData } from "../types"
import { clearParticipantFieldWhenNotIncludedInOptions, getAppointmentParticipanOptions } from "../utils"

const AppointmentParticipantsSection: FC<Props> = ({ hideExtraParticipantFields, isEditing }) => {
  const {
    values: { participant, start, selectedSlot },
    initialValues,
    setFieldValue,
    isSubmitting,
  } = useFormikContext<AppointmentFormData>()
  const { practitionersRefs, devicesRefs, roomsRefs, isLoading } = useAppointmentParticipants({
    appointmentType: participant?.[5]?.actor,
    startDate: selectedSlot?.start,
    endDate: selectedSlot?.end,
  })
  const isSameSlot = initialValues.start && start && isEqual(initialValues.start, start)
  const fieldsDisabled = !selectedSlot

  const { devicesRefsOptions, roomsRefsOptions } = useMemo(
    () =>
      getAppointmentParticipanOptions({
        isEditing,
        isSameSlot,
        devicesRefs,
        practitionersRefs,
        roomsRefs,
        participants: initialValues.participant,
      }),
    [isEditing, isSameSlot, devicesRefs, practitionersRefs, roomsRefs, initialValues.participant],
  )

  useEffect(() => {
    if (isSameSlot) {
      initialValues.participant.forEach((participant, index) => {
        setFieldValue(`participant[${index}]`, participant)
      })
    }
  }, [isSameSlot, initialValues.participant])

  useEffect(() => {
    if (isSameSlot || isSubmitting) return
    clearParticipantFieldWhenNotIncludedInOptions({
      fieldName: "participant[1].actor",
      options: practitionersRefs,
      participant: participant[1],
      setFieldValue,
    })
    clearParticipantFieldWhenNotIncludedInOptions({
      fieldName: "participant[3].actor",
      options: roomsRefs,
      participant: participant[3],
      setFieldValue,
    })
    clearParticipantFieldWhenNotIncludedInOptions({
      fieldName: "participant[4].actor",
      options: devicesRefs,
      participant: participant[4],
      setFieldValue,
    })
  }, [isSameSlot, practitionersRefs, roomsRefs, devicesRefs, participant, isSubmitting])

  return (
    <fieldset
      className="flex flex-col gap-2 disabled:opacity-40 disabled:pointer-events-none"
      disabled={fieldsDisabled}
    >
      {!hideExtraParticipantFields && (
        <>
          <ReferenceDropdownField
            field="participant[3].actor"
            label="Room"
            options={roomsRefsOptions}
            className="w-full"
            isClearable
            disabled={fieldsDisabled}
            loading={isLoading}
          />
          <ReferenceDropdownField
            field="participant[4].actor"
            label="Device"
            options={devicesRefsOptions}
            className="w-full"
            isClearable
            disabled={fieldsDisabled}
            loading={isLoading}
          />
        </>
      )}
      <TextAreaField field="comment" label="Notes" rows={5} />
    </fieldset>
  )
}

type Props = {
  hideExtraParticipantFields?: boolean
  isEditing?: boolean
}

export { AppointmentParticipantsSection }
