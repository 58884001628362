import { DatesSetArg, createPlugin } from "@fullcalendar/core"
import { ViewProps } from "@fullcalendar/core/internal"
import DayGridPlugin from "@fullcalendar/daygrid"
import FullCalendar from "@fullcalendar/react"
import TimeGridPlugin from "@fullcalendar/timegrid"
import { isSameDay } from "date-fns"
import { Appointment } from "fhir"
import { Button } from "primereact/button"
import { CalendarMonthChangeEvent } from "primereact/calendar"
import { FC, createRef, useMemo, useReducer, useState } from "react"

import {
  AppointmentFormOverlay,
  INITIAL_VALUES,
  getMonthDateBounds,
  sanitize,
  useBookAppointment,
  usePractitionerAppointments,
  useUnbookAppointment,
  useUpdateAppointment,
} from "appointments"
import { ConfirmDialog } from "commons"
import { CustomAutoComplete } from "commons/components/CustomAutoComplete"
import { ReferenceDropdown } from "commons/components/ReferenceDropdown"
import { useParamsFilters } from "commons/hooks"
import { useOrganizationContext } from "organization"

import { getAppointmentsDateRangeFromDatesSet, getCalendarDateFromDatesSet, getEvents } from "../utils"
import { AgendaView } from "./AgendaView"
import { AppointmentCalendar } from "./AppointmentCalendar"
import "./CalendarView.css"

const CalendarView: FC = () => {
  const { filters, updateFilters } = useParamsFilters(["patientId", "practitionerId", "appointmentType"])
  const { currentOrganizationId, organizationPractitionerRefs, healthcareServicesRefs } = useOrganizationContext()
  const calendarRef = createRef<FullCalendar>()

  const [datesSet, setDatesSet] = useState<DatesSetArg>()
  const datesRange = getAppointmentsDateRangeFromDatesSet(datesSet)
  const calendarDate = getCalendarDateFromDatesSet(datesSet)

  const {
    initialValues,
    isNew,
    showOverlayForm,
    confirmUnbookItem,
    reset,
    add: addAppointmentAction,
    unbookItem,
  } = useReducerState()

  const { appointments } = usePractitionerAppointments({
    currentOrganizationId,
    start: datesRange?.start,
    end: datesRange?.end,
    patientId: filters.patientId,
    practitionerId: filters.practitionerId,
    appointmentType: filters.appointmentType,
  })

  const events = useMemo(() => getEvents(appointments ?? []), [appointments])

  const { bookAppointment } = useBookAppointment(reset)
  const { updateAppointment } = useUpdateAppointment(reset)
  const { unbookAppointment } = useUnbookAppointment({ onSuccess: reset })

  const getCurrentAppointmentsDate = (date: Date) =>
    events.filter((ev) => ev.start && isSameDay(new Date(ev.start.toString()), date))

  const onSubmit = (appointment: Appointment) => {
    isNew ? bookAppointment(sanitize(appointment)) : updateAppointment(sanitize(appointment))
  }
  const onUnbook = (appointment: Appointment) => unbookAppointment(appointment.id)

  const handleCalendarMonthChange = ({ month, year }: CalendarMonthChangeEvent) => {
    const { start } = getMonthDateBounds({ month, year })
    calendarRef.current?.getApi().gotoDate(start)
    calendarRef.current?.getApi().changeView("dayGridMonth")
  }

  const handleDateSelection = (date?: Date) => {
    if (date) {
      calendarRef.current?.getApi().gotoDate(date)
      calendarRef.current?.getApi().changeView("agenda")
    }
  }

  // passes props to AgendaPlugin
  class MorePropsToView {
    transform(viewProps: ViewProps) {
      return {
        ...viewProps,
        unbook: unbookItem,
      }
    }
  }

  const AgendaPlugin = createPlugin({
    name: "AgendaView",
    views: {
      agenda: AgendaView,
    },
    viewPropsTransformers: [MorePropsToView],
  })

  return (
    <div className="p-2 bg-white h-full flex">
      <div className="flex-1 pl-2 pt-2">
        <FullCalendar
          ref={calendarRef}
          plugins={[DayGridPlugin, TimeGridPlugin, AgendaPlugin]}
          initialView="agenda"
          events={events}
          headerToolbar={{
            left: "title",
            right: "prev,today,next dayGridMonth timeGridWeek agenda",
          }}
          buttonText={{ month: "Month", week: "Week", agenda: "Agenda" }}
          height="100%"
          eventMouseEnter={(mouseEnterInfo) => {
            const el = mouseEnterInfo?.el
            el.setAttribute("title", mouseEnterInfo?.event?.title)
          }}
          datesSet={(dates) => {
            if (dates?.startStr !== datesSet?.startStr) {
              setDatesSet(dates)
            }
          }}
        />
      </div>
      <div className="flex flex-col pl-3 flex-none w-2/6 md:w-[35%] 2xl:w-1/4">
        <AppointmentCalendar
          currentDate={calendarDate}
          selectDate={handleDateSelection}
          currentDateAppointments={getCurrentAppointmentsDate}
          onMonthChange={handleCalendarMonthChange}
        />
        <Button
          label="Create appointment"
          className="block outline-none ring-0 w-full text-white p-3 mt-4 button-primary"
          onClick={addAppointmentAction}
        />

        <div className="mt-4 rounded-md bg-neutral-100 px-4 py-6 w-full space-y-4">
          <CustomAutoComplete
            label="Patient"
            handleChange={(param) => {
              updateFilters({ patientId: param })
            }}
            className="w-full"
            inputClassName="w-full"
            valueReferenceId={filters.patientId}
          />
          <ReferenceDropdown
            label="Practitioner"
            options={organizationPractitionerRefs}
            className="w-full"
            value={organizationPractitionerRefs.find(({ id }) => id === filters.practitionerId)}
            isClearable
            handleChange={(value) => updateFilters({ practitionerId: value?.id })}
          />
          <ReferenceDropdown
            label="Appointment Type"
            optionLabel="id"
            options={healthcareServicesRefs}
            handleChange={(value) => updateFilters({ appointmentType: value?.id })}
            value={healthcareServicesRefs.find(({ id }) => id === filters.appointmentType)}
            isClearable
          />
        </div>

        <AppointmentFormOverlay
          visible={showOverlayForm}
          isEditing={!isNew}
          appointment={initialValues}
          onHide={reset}
          onSubmit={onSubmit}
        />

        <ConfirmDialog
          confirmText="Are you sure you want to unbook this appointment"
          actionName="Unbook"
          visible={confirmUnbookItem !== undefined}
          onConfirm={() => onUnbook(confirmUnbookItem as Appointment)}
          hideDialog={reset}
        />
      </div>
    </div>
  )
}

const initialState: State = {
  showOverlayForm: false,
  initialValues: INITIAL_VALUES,
  isNew: false,
  selectedDate: new Date(),
  confirmUnbookItem: undefined,
  agendaDate: undefined,
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "add" | "edit" | "unbook" | "selectDate" | "filterByPatient" | "filterByPractitioner"
    payload?: Date | Appointment | string
  },
) => {
  switch (type) {
    case "reset":
      return {
        ...initialState,
        initialValues: { ...initialState.initialValues, start: state.selectedDate },
        selectedDate: state.selectedDate,
      }
    case "add":
      return { ...state, showOverlayForm: true, isNew: true, agendaDate: undefined, confirmUnbookItem: undefined }
    case "edit":
      return {
        ...state,
        showOverlayForm: true,
        initialValues: payload as Appointment,
        isNew: false,
        agendaDate: undefined,
      }
    case "unbook":
      return { ...state, confirmUnbookItem: payload as Appointment }
    case "selectDate":
      return {
        ...state,
        initialValues: { ...state.initialValues, start: payload as Date },
        selectedDate: payload as Date,
        confirmUnbookItem: undefined,
        agendaDate: payload as Date,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ initialValues, isNew, showOverlayForm, agendaDate, confirmUnbookItem }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const add = () => {
    dispatch({ type: "add" })
  }

  const edit = (appointment: Appointment) => {
    dispatch({ type: "edit", payload: appointment })
  }

  const unbookItem = (appointment: Appointment) => {
    dispatch({ type: "unbook", payload: appointment })
  }

  const selectDate = (selectedDate: Date) => {
    dispatch({ type: "selectDate", payload: selectedDate })
  }

  return {
    initialValues,
    isNew,
    showOverlayForm,
    confirmUnbookItem,
    reset,
    add,
    edit,
    selectDate,
    unbookItem,
    agendaDate,
  }
}

type State = {
  showOverlayForm: boolean
  initialValues: Appointment
  isNew: boolean
  selectedDate: Date
  confirmUnbookItem?: Appointment
  agendaDate?: Date
  practitionerFilterId?: string
  patientFilterId?: string
}

export { CalendarView }
