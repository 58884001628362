import { useInfiniteQuery } from "@tanstack/react-query"
import {
  ChargeItemDefinition,
  Coding,
  getResources,
  InventoryItem,
  isMedicationKnowledge,
  MedicationKnowledge,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useCIDQueryFunction } from "commons"
import { MEDICATION_CATALOG } from "data"
import { useOrganizationContext } from "organization"
import { getBasePrice, getBillToPatientFeePrice, getCidIdentifier, getCommonCode, getMedCodes } from "utils"

import { allowedMKDoseFormsToGetReferencePrices, referencePriceQuantities } from "administration/data"
import { settingsQueryKeys } from "../../query-keys"
import { MedicationsAdvanceFilter, MedItem, ReferencePriceItem } from "../../types"

const useMksByCategory = ({
  category,
  enabled = true,
  filters,
  organizationId,
  useReferencePriceQuantities,
}: {
  organizationId: string
  category: MEDICATION_CATALOG
  filters: Filters
  enabled: boolean
  useReferencePriceQuantities?: boolean
}) => {
  const { search } = useClient()
  const { location } = useOrganizationContext()
  const { mkCatalogs: catalogs, searchText, medsClassificationCodes } = filters
  const queryKey = settingsQueryKeys.meds({
    organizationId,
    category,
    catalogs,
    medsClassificationCodes,
    archived: false,
    searchText,
  })
  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(catalogs?.length ? { catalogHeader: catalogs.join(",") } : {}),
        ...(searchText ? { termsearch: searchText } : {}),
        "catalogHeader:Composition.author:Organization.type": category,
        ...(medsClassificationCodes?.length ? { classification: medsClassificationCodes.join(",") } : {}),
        _count: "50",
        _page: `${pageParam}`,
        _sort: "-code",
        status: "active",
        "has-cid-in-org": organizationId,
        _revinclude: "InventoryItem:medication",
      })

      const bundle = await search({ endpoint: "MedicationKnowledge", filters, signal })
      const medicationKnowledge = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const inventoryItems = getResources<InventoryItem>(bundle, "InventoryItem")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined
      const total = bundle.total ?? 0
      const mkCodes = getMedCodes({
        meds: medicationKnowledge,
        withQty: true,
        referenceQuantities: useReferencePriceQuantities ? referencePriceQuantities : [],
      })

      const chargeItemDefinitions = await getChargeItemDefinitions(organizationId, {
        billToPracticeOrInsuranceCIDs: mkCodes,
      })

      return {
        medicationKnowledge,
        mkCodes,
        cids: chargeItemDefinitions.billToPracticeOrInsuranceCIDs,
        next,
        total,
        inventoryItems,
      }
    },
    refetchOnWindowFocus: false,
    meta: { context: { queryKey, ...filters } },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    enabled,
  })

  const { medicationKnowledge, mkCodes, medsWithCID } = useMemo(() => {
    const { medicationKnowledge, mkCodes, cids, inventoryItems } = data?.pages.reduce(
      (acc, page) => {
        return {
          ...acc,
          medicationKnowledge: [...acc.medicationKnowledge, ...page.medicationKnowledge],
          mkCodes: [...acc.mkCodes, ...(page.mkCodes ?? [])],
          cids: { ...acc.cids, ...page.cids },
          inventoryItems: [...acc.inventoryItems, ...page.inventoryItems],
        }
      },
      {
        medicationKnowledge: Array<MedicationKnowledge>(),
        mkCodes: Array<Coding>(),
        cids: {} as Record<string, ChargeItemDefinition>,
        inventoryItems: Array<InventoryItem>(),
      },
    ) ?? { medicationKnowledge: [], mkCodes: [], cids: {}, inventoryItems: [] }

    const inventory = inventoryItems.reduce<Record<string, InventoryItem>>((acc, item) => {
      // TODO: Change this to bind by SKU when migrated in meds
      //const medId = item.code?.[0]?.coding && getCommonCode({ codes: item.code?.[0]?.coding })
      const medId = item.association?.find((a) => isMedicationKnowledge(a.relatedItem))?.relatedItem?.id

      return medId && item.instance?.location?.id === location?.id ? { ...acc, [medId]: item } : acc
    }, {})

    const medsWithCID = medicationKnowledge.reduce((acc, mk) => {
      const mkCode = getCommonCode({ codes: mk.code?.coding })
      const cidIdentifier = getCidIdentifier(mkCode)

      const pc = cids?.[cidIdentifier]?.propertyGroup?.[0]?.priceComponent
      const basePrice = getBasePrice(pc)?.amount
      const fee = getBillToPatientFeePrice(pc)?.amount

      const isAllowedToGetReferencePrices =
        !!allowedMKDoseFormsToGetReferencePrices[mk.doseForm?.coding?.[0]?.code ?? ""]

      const referencePrices =
        isAllowedToGetReferencePrices && useReferencePriceQuantities
          ? referencePriceQuantities.map<ReferencePriceItem>((qty) => {
              const cidIdentifier = getCidIdentifier(mkCode, qty)
              const pc = cids?.[cidIdentifier]?.propertyGroup?.[0]?.priceComponent

              return { qty, price: getBasePrice(pc)?.amount?.value ?? 0 }
            })
          : []

      const inventoryItem = inventory[mk.id as string]

      return [
        ...acc,
        {
          mk,
          price: basePrice?.value ?? 0,
          fee: fee?.value ?? 0,
          referencePrices,
          inventory: inventoryItem && {
            item: inventoryItem,
            qty: inventoryItem.netContent ?? { value: 0, unit: "unit" },
            location: inventoryItem.instance?.location,
          },
        } as MedItem,
      ]
    }, Array<MedItem>())

    return { medicationKnowledge, mkCodes, medsWithCID }
  }, [data?.pages])

  return {
    medicationKnowledge: medicationKnowledge ?? [],
    medicationKnowledgeCodes: mkCodes,
    medsWithCID,
    isLoading: isLoading,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type Filters = MedicationsAdvanceFilter & { searchText?: string }

export { useMksByCategory }
