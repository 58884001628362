import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik"
import { classNames } from "primereact/utils"

import { AddFieldArrayItemButton } from "commons"
import { dayOfWeek } from "data"

import { useMemo } from "react"
import { DayHours } from "./DayHours"
import { OperationHours, UsedRegularDays } from "./types"

const RegularHours = () => {
  const {
    values: { regularHours },
    setFieldValue,
  } = useFormikContext<OperationHours>()

  const usedDays = useMemo(
    () => regularHours.reduce<UsedRegularDays[]>((acc, day, index) => [...acc, { index, days: day.dayCodes }], []),
    [regularHours],
  )

  return (
    <FieldArray name="regularHours">
      {({ name: fieldName, push, remove }: FieldArrayRenderProps) => {
        const allUsedDays = usedDays.flatMap((d) => d.days)
        const allowAddDay = !dayOfWeek.every(({ code }) => allUsedDays.includes(code))

        const toggleDay = (regularHoursIndex: number, dayCode: string, isSelected: boolean) => {
          const newDayCodes = regularHours[regularHoursIndex].dayCodes.filter((code) => code !== dayCode)
          if (isSelected) newDayCodes.push(dayCode)
          setFieldValue(`regularHours[${regularHoursIndex}].dayCodes`, newDayCodes)
        }

        return (
          <div className="flex flex-col flex-1 gap-4 divide-y">
            {regularHours.map((dayHours, daysIndex) => (
              <div key={daysIndex} className="flex justify-between pt-5 first:pt-0">
                <DayHours parentField={`${fieldName}[${daysIndex}]`} onRemoveDay={() => remove(daysIndex)} />
                <div className="flex gap-6">
                  {dayOfWeek.map(({ code }) => {
                    const invalidDayIndexes = usedDays
                      .filter((d) => d.index !== daysIndex)
                      .flatMap((d) => d.days)
                      .join("|")
                    const isSelected = dayHours.dayCodes.includes(code)
                    const dayIsDisabled = invalidDayIndexes.includes(code)

                    return (
                      <div
                        key={code}
                        className={classNames(
                          "flex items-center justify-center w-8 h-8 text-sm capitalize rounded-full border",
                          dayIsDisabled
                            ? "bg-gray-200 border-gray-300 text-white cursor-default"
                            : "cursor-pointer border-primary",
                          { "bg-primary text-white": isSelected },
                        )}
                        onClick={dayIsDisabled ? undefined : () => toggleDay(daysIndex, code, !isSelected)}
                      >
                        {code[0]}
                      </div>
                    )
                  })}
                </div>
              </div>
            ))}
            <AddFieldArrayItemButton
              label="Add New Hour"
              disabled={!allowAddDay}
              onClick={() =>
                push({
                  days: "",
                  dayCodes: [],
                  hours: [
                    {
                      openingTime: "08:00:00",
                      closingTime: "20:00:00",
                    },
                  ],
                })
              }
            />
          </div>
        )
      }}
    </FieldArray>
  )
}

export { RegularHours }
