import { Practitioner } from "fhir"
import { asReference, getFirstEmail, getFirstPhone } from "fhir/utils"
import { FormikHelpers } from "formik"
import * as Yup from "yup"

import { EmailField, InformationCard, PhoneField, useUpdateUserEmail } from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useLoginContext } from "security"
import { telecomSchema } from "utils"

import { ProfileSection } from "../data"
import { useUpdatePractitioner } from "../hooks"

const ContactInformation = () => {
  const { loggedInPractitioner, loggedInPractitionerId, reloadPractitioner } = useLoginContext()
  const { patchPractitioner, isUpdating } = useUpdatePractitioner(reloadPractitioner)
  const { updateEmail, isPending } = useUpdateUserEmail()

  const initialValues: Partial<Practitioner> = { telecom: loggedInPractitioner.telecom }
  const { telecom } = initialValues

  const onSubmit = (data: Partial<Practitioner>, _: FormikHelpers<Partial<Practitioner>>, activeItem?: string) => {
    if (activeItem === "Email" && telecom?.[0]?.value !== data.telecom?.[0].value) {
      updateEmail({
        resourceId: loggedInPractitionerId,
        resourceType: "Practitioner",
        email: data.telecom?.[0].value as string,
      })
    } else {
      patchPractitioner({
        id: loggedInPractitionerId,
        ...data,
      })
    }
  }

  return (
    <ReplaceFormProvider>
      <InformationCard
        id={ProfileSection.CONTACT}
        title="Contact Information"
        lineEditData={[]}
        data={{
          Email: getFirstEmail(telecom),
          Telephone: getFirstPhone(telecom),
        }}
        initialValue={initialValues}
        validationSchema={Yup.object().shape({ telecom: Yup.array(telecomSchema()) })}
        isUpdating={isUpdating || isPending}
        onSubmit={onSubmit}
        className="profile-card-section p-4"
      >
        {({ currentActiveItem }: { currentActiveItem?: string }) => {
          return currentActiveItem === "Email" ? (
            <EmailField
              field="telecom[0].value"
              label="Email"
              className="p-fluid flex-1"
              validateDuplicate
              subject={asReference(loggedInPractitioner)}
            />
          ) : (
            <PhoneField field="telecom[1].value" label="Phone" className="p-fluid flex-1" />
          )
        }}
      </InformationCard>
    </ReplaceFormProvider>
  )
}

export { ContactInformation }
