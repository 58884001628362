import { faCalendarClock, faCalendarDay, faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { faUser, faUserDoctor } from "@fortawesome/pro-solid-svg-icons"
import { format } from "date-fns/format"
import { AuditEvent } from "fhir"
import { FC } from "react"

import { formatsByTypes } from "data"

import { EmptyMessage } from "../../components/EmptyMessage"
import { StackedListContainer } from "../../components/StackedListContainer"
import { StackedListItemProps } from "../../components/StackedListItem"
import { RescheduleEventData } from "../types"

const ActivityHistory: FC<Props> = ({ activityRecordsByMR }) => {
  const hasActivityRecord = !!(activityRecordsByMR && Object.keys(activityRecordsByMR).length)

  return hasActivityRecord ? (
    <div className="flex flex-col w-full h-full divide-y overflow-y-auto">
      {hasActivityRecord && (
        <>
          {Object.entries(activityRecordsByMR).map(([mrId, mrActivity]) => (
            <StackedListContainer
              key={mrId}
              data={mrActivity as RescheduleEventData[]}
              className="grid grid-flow-row list-none w-full"
              itemModelBuilder={(item) => modelBuilder(item, Object.keys(activityRecordsByMR ?? {}).length > 1)}
            />
          ))}
        </>
      )}
    </div>
  ) : (
    <EmptyMessage
      icon={faCalendarClock}
      message="No activity recorded for this order"
      subMessage={false}
      className="h-full content-center"
    />
  )
}

const modelBuilder = (
  { entity, participants, from, to, recorded, subtype }: RescheduleEventData,
  multipleMRs?: boolean,
): StackedListItemProps => {
  const mr = entity?.[0]?.what?.display ?? entity?.[0]?.name

  return {
    leftData: [
      {
        lineItems: [
          ...(multipleMRs && mr ? [{ name: "Medication", value: mr }] : []),
          ...(multipleMRs && mr ? [{ name: "", value: "-" }] : []),
          { name: "Event", value: subtype?.[0]?.display ?? subtype?.[0]?.code ?? "Unspecified" },
        ],
      },
      {
        lineItems: [
          ...participants.map((p) => ({
            name: p.type,
            value: `${p.requestor ? "Requestor: " : ""}${p.name}`,
            icon: p.type === "Practitioner" ? faUserDoctor : faUser,
          })),
          {
            name: "Date",
            value: format(recorded, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
            icon: faCalendarDays,
          },
          ...(from
            ? [
                {
                  name: "Rescheduled from",
                  value: format(from, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
                  icon: faCalendarClock,
                },
              ]
            : []),
          ...(to
            ? [
                {
                  name: "Rescheduled to",
                  value: format(to, formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE),
                  icon: faCalendarDay,
                },
              ]
            : []),
        ],
      },
    ],
  }
}

type Props = {
  activityRecordsByMR?: Record<string, AuditEvent[]>
}

export { ActivityHistory }
