import { faBarcode, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CodeableConcept, InventoryItem, MedicationKnowledge, asReference } from "fhir"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useId, useMemo, useState } from "react"

import {
  Badge,
  Button,
  DialogFormContainer,
  DropdownMenu,
  EmptyMessage,
  InfiniteScroll,
  SearchInput,
  SkeletonLoader,
  useCrudReducer,
} from "commons"
import { getMKDisplayText } from "commons/meds"
import { useOrganizationContext } from "organization"
import { getBadgeColor, getCommonCode } from "utils"

import { useCreateInventoryItem, useInventory } from "../../hooks"
import { InventoryForm } from "./InventoryForm"
import { getInventoryInitialValues, inventoryItemValidationSchema } from "./validations"

const InventoryContainer = () => {
  const { currentOrganization, location } = useOrganizationContext()
  const [textFilter, setTextFilter] = useState<string>()

  const {
    showSlide: showForm,
    add,
    isNew,
    initialValue,
    edit,
    reset,
  } = useCrudReducer({
    defaultEntity: getInventoryInitialValues(asReference(currentOrganization), location ? asReference(location) : {}),
  })
  const { isLoading, inventoryItems, mkSkuCodes, hasNextPage, fetchNextPage } = useInventory({
    textFilter,
  })

  const { createInventoryItem } = useCreateInventoryItem(reset)

  const handleSubmit = (item: InventoryItem & { med?: MedicationKnowledge }) => {
    createInventoryItem(item)
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" containerClassName="flex-1" />

  const inventoryItemsData = useMemo(
    () =>
      inventoryItems.map((item) => ({
        inventoryData: {
          code: item.code,
          name: item.name?.[0]?.name ?? item.code?.[0]?.text,
        },
        status: item.status,
        total: item.netContent?.value,
        externalAction: [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
            command: () =>
              edit({
                ...item,
                med: {
                  code: item.code?.[0],
                  textDisplayedInField: getMKDisplayText({ code: item.code?.[0] } as MedicationKnowledge),
                },
              }),
          },
        ],
      })),
    [inventoryItems],
  )

  const statusBodyTemplate = ({ status }: { status: string }) => {
    return <Badge className="@md:inline-flex" {...getBadgeColor(status)} />
  }
  const externalActionBodyTemplate = ({
    externalAction,
  }: {
    externalAction: { label: string; icon: JSX.Element; command: () => void }[]
  }) => {
    return (
      <div className="flex items-center justify-between">
        {externalAction.length > 1 ? (
          <DropdownMenu dropdownMenuItems={externalAction} />
        ) : (
          <button
            key={1}
            title={externalAction?.[0]?.label}
            onClick={externalAction?.[0]?.command}
            className="flex items-center justify-center p-1 text-gray-500 rounded-md cursor-pointer"
          >
            {externalAction?.[0]?.icon}
          </button>
        )}
      </div>
    )
  }

  const inventoryItemBodyTemplate = ({
    inventoryData: { code, name },
  }: {
    inventoryData: { code: CodeableConcept[]; name: string }
  }) => {
    const sku = getCommonCode({ codes: code?.[0]?.coding })

    const header = (
      <div
        title="Item name"
        className="overflow-hidden text-ellipsis line-clamp-1 whitespace-normal break-words font-medium max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-full"
      >
        {name}
      </div>
    )

    const details = (
      <>
        <span title="Code" className="flex items-center">
          <FontAwesomeIcon icon={faBarcode} className="mr-1.5 text-gray-500" />
          {sku}
        </span>
      </>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center w-full">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                <div className="mt-2 flex">
                  <div className="flex items-center text-xs text-gray-400 divide-x divide-gray-400 gap-2">
                    {details}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  return (
    <div className="flex flex-col flex-1 px-1.5 pt-1 gap-2">
      <SearchInput search={setTextFilter} isLoading={isLoading} placeholder="Search inventory..." />
      {isLoading ? (
        loader()
      ) : (
        <>
          <div className="flex-1 overflow-y-auto">
            {inventoryItemsData.length ? (
              <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
                <DataTable value={inventoryItemsData} loading={isLoading}>
                  <Column field="inventoryData" body={inventoryItemBodyTemplate} headerClassName="bg-transparent" />
                  <Column
                    field="total"
                    header="Availability"
                    bodyClassName="text-gray-400"
                    headerClassName="bg-transparent text-gray-400 font-semibold"
                  />
                  <Column
                    field="status"
                    header="Status"
                    body={statusBodyTemplate}
                    headerClassName="bg-transparent text-gray-400 font-semibold"
                  />
                  <Column field="external_action" headerClassName="bg-transparent" body={externalActionBodyTemplate} />
                </DataTable>
              </InfiniteScroll>
            ) : (
              <div className="flex h-full">
                <EmptyMessage itemTitle="Inventory item" icon={faSearch} />
              </div>
            )}
          </div>
          <div className="flex justify-end p-4 border-t border-gray-200">
            <Button buttonStyle="primary" label="Add to inventory" onClick={add} size="lg" />
          </div>
        </>
      )}
      <DialogFormContainer
        onCancel={reset}
        showForm={showForm}
        onSubmit={handleSubmit}
        initialValue={initialValue}
        validationSchema={inventoryItemValidationSchema}
        saveLabel={isNew ? "Add" : "Update"}
        useFormik
        title={`${isNew ? "Add" : "Edit"} Inventory Item`}
      >
        <InventoryForm isEditing={!isNew} mksOnInventoryCodes={mkSkuCodes} />
      </DialogFormContainer>
    </div>
  )
}

export { InventoryContainer }
