import { Appointment } from "fhir"
import { useFormikContext } from "formik"
import { Calendar } from "primereact/calendar"
import { classNames } from "primereact/utils"
import { FC, useMemo } from "react"

import { useFindAvailableSlots, useScheduleAppointmentContext } from "../hooks"
import { getMonthDateBounds } from "../utils"
import "./AppointmentCalendar.css"

const AppointmentCalendar: FC = () => {
  const {
    values: { participant },
  } = useFormikContext<Appointment>()
  const {
    selectedMonthBounds: { start, end },
    updateSelectedMonthBounds,
    selectedDate,
    updateSelectedDate,
  } = useScheduleAppointmentContext()
  const healthcareServiceReference = participant?.[5]?.actor
  const { slots } = useFindAvailableSlots({
    healthcareService: healthcareServiceReference,
    startDate: start,
    endDate: end,
    patient: participant?.[0]?.actor,
    practitioner: participant?.[1]?.actor,
  })
  const enabledDates = useMemo(() => slots.map((slot) => new Date(slot.start)), [slots])
  const isDisabled = !participant?.[0]?.actor && !!participant?.[1]?.actor && !!participant?.[5]?.actor

  return (
    <Calendar
      value={selectedDate}
      className="custom-appointment-calendar-sm"
      panelClassName={classNames("border-none border-0", { "bg-opacity-40": isDisabled })}
      onChange={(e) => {
        updateSelectedDate(e.value as Date)
      }}
      inline
      disabled={isDisabled}
      onMonthChange={(e) => {
        updateSelectedMonthBounds(getMonthDateBounds(e))
      }}
      enabledDates={enabledDates}
    />
  )
}

export { AppointmentCalendar }
