import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const useParamsFilters = <T extends string[]>(params: [...T]) => {
  type ParamsRecord = {
    [K in T[number]]?: string
  }
  const [filters, setFilters] = useState<ParamsRecord>({})

  const [searchParams, setSearchParams] = useSearchParams()

  const updateFilters = (paramsRecord: ParamsRecord) => {
    Object.entries(paramsRecord).forEach(([param, value]) => {
      if (value) {
        searchParams.set(param, value as string)
      } else {
        searchParams.delete(param)
      }
    })
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const filters = params.reduce((acc, param) => {
      const value = searchParams.get(param)
      if (value) return { ...acc, [param]: value }
      return acc
    }, {} as ParamsRecord)

    setFilters(filters)
  }, [searchParams])

  return { filters, updateFilters }
}

export { useParamsFilters }
